import axios from 'axios'
import { getLocalStorage,encryptData,userData, encryptApiData, getUniqueDeviceId,userEmail } from '../utils/utils';

const applicationjson = 'application/json';
export const defaultErrorHandler = error => {
  if (!error.response) {
    // network error
    console.log(error)
    return Promise.reject(error);
  } else if (error && error.response && error.response.status === 401) {
    localStorage.removeItem("roleId");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissions");
    localStorage.removeItem("expiration");
    localStorage.removeItem("token");
    sessionStorage.clear();
  }
  return Promise.reject(error);
};
export const errorHandler = error => {
  if (!error.response) {
    // network error
    console.log(error)
    return Promise.reject(error);
  } else if (error && error.response && error.response.status === 401) {
    localStorage.removeItem("roleId");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissions");
    localStorage.removeItem("expiration");
    localStorage.removeItem("token");
    sessionStorage.clear();
    return Promise.reject(error.response);
  } else {
    return Promise.reject(error);
  }
};

const getInitializedApi = (containType, responseType = 'json') => {
  const userId = userData();
  const email = userEmail();
  let userDeviceId = getUniqueDeviceId();
  let concatString= `${userId}:${userDeviceId}`
  const header = {
    Authorization: `Bearer ${getLocalStorage("token")}`,
    XApiKey: '93d9607f-ab1e-4ce6-bfd3-e11bbde6baf5',
    // userId: encryptApiData(concatString),
    userId: btoa(concatString),
    distaKey:email
  };

  return axios.create({
    responseType: responseType,
    withCredentials: false,
    headers: header.Authorization ? header : {
      'Accept': applicationjson,
      'Content-Type': containType,
      'X-JsonResponseCase': 'camel',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
    },
  });
};
const getInitializedApis= (containType, responseType = 'blob') => {
  const header = {
    Authorization: `Bearer ${getLocalStorage("token")}`,
    XApiKey: '93d9607f-ab1e-4ce6-bfd3-e11bbde6baf5',
    userId: encryptData(userData()),
  };

  return axios.create({
    responseType: responseType,
    withCredentials: false,
    headers: header.Authorization ? header : {
      'Accept': applicationjson,
      'Content-Type': containType,
      'X-JsonResponseCase': 'camel',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

const initializeExternalApi = (containType, functionsKey, responseType = 'json') => {
  return axios.create({
    responseType: responseType,
    withCredentials: false,
    headers: {
      'Accept': applicationjson,
      'Content-Type': containType,
      'X-JsonResponseCase': 'camel',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
      'x-functions-key': functionsKey
    },
  });
};
const initializeExternalApiBlob = (containType, responseType = 'json') => {
  return axios.create({
    responseType: responseType,
    withCredentials: false,
    headers: {
      'Accept': applicationjson,
      'Content-Type': containType,
      'X-JsonResponseCase': 'camel',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
      'XApiKey': '93d9607f-ab1e-4ce6-bfd3-e11bbde6baf5',
    },
  });
};

export const convertToFormUrlencodedValue = params => {
  return Object.keys(params)
    .map(key => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join('&');
};

export const post = (url, data) => {
  return getInitializedApi(applicationjson)
    .post(url, data)
    .catch(errorHandler || defaultErrorHandler);
};
export const postIntegration = (url, data, key) => {
  return initializeExternalApi(applicationjson, key)
    .post(url, data)
    .catch(errorHandler || defaultErrorHandler);
};

export const postForBlob = (url, data) => {
  return getInitializedApi(applicationjson, 'blob')
    .post(url, data)
    .catch(errorHandler || defaultErrorHandler);
};

export const put = (url, data) => {
  return getInitializedApi(applicationjson)
    .put(url, data)
    .catch(errorHandler || defaultErrorHandler);
};

export const patch = (url, data) => {
  return getInitializedApi(applicationjson)
    .patch(url, data)
    .catch(errorHandler || defaultErrorHandler);
};

export const get = (url, data) => {
  return getInitializedApi(applicationjson)
    .get(url, data)
    .catch(errorHandler || defaultErrorHandler);
};
export const gets = (url, data) => {
  return getInitializedApis(applicationjson)
    .get(url, data)
    .catch(errorHandler || defaultErrorHandler);
};

export const deleteReq = (url, data) => {
  return getInitializedApi(applicationjson)
    .delete(url, data)
    .catch(errorHandler || defaultErrorHandler);
};
export const getDownloadData = (url, data, Header, config) => {
  return getInitializedApi('application/vnd.ms-excel', 'blob')
    .post(url, data, Header, config)
    .catch(errorHandler || defaultErrorHandler);
};
export const getDownloadDatas = (url, data, Header, config) => {
  return getInitializedApis('application/vnd.ms-excel', 'blob')
    .post(url, data, Header, config)
    .catch(errorHandler || defaultErrorHandler);
};
export const postUploadData = (url, formData) => {
  return getInitializedApi('multipart/form-data; boundary=----WebKitFormBoundaryHJonIRac3rfb2EBf')
    .post(url, formData)
    .catch(errorHandler || defaultErrorHandler);
};
export const getForBlob = (url) => {
  return initializeExternalApiBlob(applicationjson)
    .get(url)
    .catch(errorHandler || defaultErrorHandler);
}