import React from "react";
import { useHistory } from "react-router-dom"
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AccountOverViewCard from './accountOverViewCard';
import ActivityOverviewCard from './activityOverviewCard';
import CampaignActivityOverviewCard from './campaignActivityOverviewCard';

import PersistencyLeadOverview from './persistencyLeadOverview';
import CalenderDashboardCard from "./calenderDashboardCard";
import LeadOverviewCard from "./leadOverviewCard";
import BusinessLeadOverviewCard from "./businessLeadOverviewCard";
import RecruitmentLeadOverviewCard from "./recruitmentLeadOverviewCard";
import CampaignLeadOverviewCard from "./campaignLeadOverviewCard";
import BusinessLeadSourceCard from "./businessLeadSourceCard";

import SalesManagementOverviewCard from "../salesManagement/salesManagementOverviewCard";
import PehlaKadamOverViewCard from "./pehlaKadamOverViewCard"
import SalesManagementOverviewCardBroca from "../activity/brocaSMA/salesManagementOverviewCardBroca";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const DashboardLayout = ({ selectedBDM, roleId, selectedView }) => {
    const history = useHistory();
    const commonState = useSelector((state) => state);
    const userState = commonState.userReducer;
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            textAlign: 'left',
            color: theme.palette.text.secondary,
            //height: '100%',
            padding: theme.spacing(2),

        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
            // height: '300px',
        },
        activityLogo: {
            //  borderRadius: 10,
            textAlign: 'center',
            backgroundColor: 'rgb(0 66 126 / 27%)',
            height: '50px',
            padding: theme.spacing(2),
        },
        heading: {
            padding: "1px 2px 2px 1px",
        }
    }));

    const classes = useStyles();
//   const redirectTopehilaKadam = () => {
//         history.push('/app/pehlaKadam/checklistDetail/10')
//     }

    return <>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} >
                    <AccountOverViewCard
                        selectedBDM={selectedBDM}
                        selectedView={selectedView}
                        roleId={roleId} classes={classes}
                        Item={Item} history={history}
                        rbhView={true}></AccountOverViewCard>

                    {roleId === 'ABH' &&
                        userState?.profileInfo?.channelid !== 2 &&
                        userState?.profileInfo?.channelid !== 3 &&
                        userState?.profileInfo?.channelid !== 13 &&
                        userState?.profileInfo?.channelid !== 21 &&
                        userState?.profileInfo?.channelid !== 19 &&
                        <SalesManagementOverviewCard
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId} classes={classes}
                            Item={Item} history={history}
                        />}
                  {/* {roleId === 'BDM' && userState?.profileInfo?.channelid === 1 &&
                    <p className="pehlaKadam" onClick={redirectTopehilaKadam}>pehlaKadam</p>
                    } */}
                    {roleId === 'ABH' && userState?.profileInfo?.channelid === 1 &&
                        <PehlaKadamOverViewCard classes={classes} Item={Item} selectedBDM={selectedBDM} selectedView={selectedView} roleId={roleId} history={history} />
                    }
                    {roleId === 'ABH' && userState?.profileInfo?.channelid === 21 &&
                        <SalesManagementOverviewCardBroca
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId} classes={classes}
                            Item={Item} history={history}
                        />}
                    {/* <p className="pehlaKadam" onClick={redirectTopehilaKadam}>pehlaKadam</p> */}

                    <ActivityOverviewCard classes={classes}
                        Item={Item}
                        selectedBDM={selectedBDM}
                        selectedView={selectedView}
                        roleId={roleId} history={history}>
                    </ActivityOverviewCard>
                    {userState.profileInfo.channelid === 2 && roleId === 'BDM' &&
                        <CampaignActivityOverviewCard
                            classes={classes}
                            Item={Item}
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId} history={history}>
                        </CampaignActivityOverviewCard>
                    }
                    {userState.profileInfo.channelid !== 3 &&  userState.profileInfo.channelid !== 19 && <>
                        <LeadOverviewCard classes={classes} Item={Item} selectedBDM={selectedBDM} selectedView={selectedView} roleId={roleId} history={history}></LeadOverviewCard>
                    </>}
                    {userState.profileInfo.channelid === 3 && <>
                        <BusinessLeadOverviewCard classes={classes} Item={Item} selectedBDM={selectedBDM} selectedView={selectedView} roleId={roleId} history={history} />
                        <RecruitmentLeadOverviewCard classes={classes} Item={Item} selectedBDM={selectedBDM} selectedView={selectedView} roleId={roleId} history={history} />
                    </>}
                </Grid>
                <Grid item xs={12} md={4}>
                    <CalenderDashboardCard classes={classes} Item={Item} selectedBDM={selectedBDM} selectedView={selectedView}>
                    </CalenderDashboardCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    {userState.profileInfo.channelid !== 21 && userState.profileInfo.channelid !== 79 && userState.profileInfo.channelid !== 19 &&
                        <PersistencyLeadOverview classes={classes}
                            Item={Item}
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId}
                            history={history}>
                        </PersistencyLeadOverview>
                    }
                    
                    {userState.profileInfo.channelid === 1 &&
                        <BusinessLeadSourceCard
                            classes={classes}
                            Item={Item}
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId} history={history}>
                        </BusinessLeadSourceCard>
                    }
                    {(userState.profileInfo.channelid !== 21 && userState.profileInfo.channelid !== 19 && roleId !== 'FPM') ? (<CampaignLeadOverviewCard
                        classes={classes}
                        Item={Item}
                        selectedBDM={selectedBDM}
                        selectedView={selectedView}
                        roleId={roleId} history={history}
                    />)
                        : ''
                    }
                </Grid>

            </Grid>
        </Box>

    </>
}

export default DashboardLayout;
