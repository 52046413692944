import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from "react-redux";
import { activateErrorMessageBar, setIsisConsent, setUserConsent } from "../../redux/actions/common";

export default function ConsentModalPopup() {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => state.commonReducer.isConsent);
    const commonState = useSelector((select) => select);
    const userState = commonState.userReducer;
    const UserId = userState?.profileInfo?.userId;

    const handleClose = () => {
        dispatch(setIsisConsent(false))
    }

    const handleSubmit = () => {
        dispatch(setUserConsent({
            userId: UserId,
            constant: true,
        })).then((res) => {
            handleClose()
        }).catch((err) => {
            console.log("err", err)
            handleClose()
            dispatch(activateErrorMessageBar("Please try again later"))
        })
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Live Tracking Consent"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    I hereby give consent for my location to be tracked for conveyance calculation and productivity enhancement
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
