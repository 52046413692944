import { createLeadLoading, createLeadError, createLeadSuccess } from './createLeadReducer'

import {
    updateLeadSuccess, getVehicalType, getExpenseType,
    getTransport, getFollowUpReason, getDroppedReason, getGCLCallDuration,
    getReschduleReason, getCallBackReason, getLeadAction,
    updateLeadStatusAction, getTeam, getLeadDetailsHistory,
    getLeadDetailsForTable, getPaymentType, getPaymentSubType, getPersistancyLeadDetailsForTable
} from './updateLeadReducer'
import {
    getBranchValue, getLeadStatus,
    getLGValue, getProducts, getSalutations,
    getSelectSP, getSPType, getLeadSource,
    getActivities, getCampaignActivities, loadingBegins, LoadingEnd, getLeadType, getLeadByIdData,
    getMeetingStatus, getZMA, bdmListForABH,TeamList, secondLevelSubordinateListForAll,secondLevelTeamListForAll, thirdLevelSubordinateListForAll,thirdLevelTeamListForAll,
    getPersistancyLeadByIdData, getCampaginLeadByIdData, getCampaginNamesData, ABHListForZBH,fourthLevelSubordinateListForAll,fourthLevelTeamListForAll
} from './leadFormReducer';

import moment from 'moment';

import {
    getLeadList, getLeadDraftListData, getLeadBacklogListData,
    getLeadsOverviewData, getLeadOverViewDashboardData, getPersistancyLeadList,
    getPersistancyLeadDraftListData, getPersistancyLeadBacklogListData,
    getCampaignLeadList, getCampaignLeadBacklogListData,
    getPersistancyLeadsOverviewData, getPersistancyLeadOverViewDashboardData,
    getCampaignLeadCampaignNamesAction, setCampaingLeadDashboardOverview, getSearchLeadList,
    getBusinessLeadsOverviewData, getBusinessLeadOverViewDashboardData,setBusinessLeadSourceDashboardOverview,
    getRecruitmentLeadsOverviewData, getRecruitmentLeadOverViewDashboardData,
} from './leadListReducer';
// import { api } from '../api/Api';

import {
    ADD_DYNAMIC_DATA, GET_ACTIVITY_BY_USER_ID, GET_CAMPAIGN_ACTIVITY_BY_USER_ID, GET_BRANCH_DETAILS, GET_LG_SP_DETAILS,
    VALIDATE_LEAD_MOBILE, VALIDATE_METING, GET_LEAD_BY_USERID, GET_LEAD_BY_ID,
    UPDATE_DYNAMIC_DATA, VALIDATE_MEETING_DATE, GET_LEAD_STATUS_ACTION, UPDATE_LEAD_SATUS_BY_ID,
    FUNCTIONS_KEY_SIMPLIFY_CREATE,
    GET_TEAM_LIST, GET_LEAD_HISTORY, GET_FILTER_LEAD_BY_USERID,
    VALIDATE_CHECKIN, DELETE_LEAD_DRAFTLIST_BY_ID, SIMPLIFY_CREATE_URL,
    CREATE_LEAD_DEDUPE_VALIDATION_URL, GetLeadOverview, GetPersistancyLeadOverview,

    GET_PERSISTANCY_LEAD_BY_USERID, GET_PERSISTANCY_LEAD_BY_ID, BDM_LIST,TEAM_LIST, GET_PERSISTANCY_LEAD_FILTER,
    GET_ALL_PERSISTENCY_LEADS, PERSISTENCY_LEAD_FILTER, REASSIGN_LEADS, GET_COMPAIGN_LEAD_BY_ID, GET_CAMPAIGN_LEAD_BY_USER_ID,
    GET_CAMPAIGN_LEAD_FILTER, GET_SUBORDINATES_LIST, LEAD_PRIORITY_URL, FUNCTIONS_KEY_LEAD_PRIORITY, GET_CAMPAIGN_LEAD_CAMPAIGN_NAMES, GET_CAMPAIGN_DASHBOARD_OVERVIEW_DATA, SEARCH_LEAD, SEND_SMS_TWILIO,
    GET_MASTER_DATA,
    GET_CAMPAIGN_NAME_FOR_FILTER,
    PERSISTENCY_ACTIVITY_FILTER,
    GET_RECRUITMENT_LEAD_OVERVIEW, GET_MASTER_DATA_BY_CHANNEL_ID, DOWNLOAD_IMAGE_URL,PEHLA_KADAM_CHECKLIST, PEHLA_KADAM_SAVE_CHECKLIST, PEHLA_KADAM_BDM_CHECKLIST, SUBORDINATE_BY_USER_ID_AND_LEVEL,
   PEHLA_KADAM_DASHBORD_OVERVIEW, PEHLA_KADAM_EXPORT_DATA, PEHLA_KADAM_BDM_VIEW,PEHLA_KADAM_RBH_CHECKLIST,PEHLA_KADAM_EXPORT_DATA_FOR_RBH,PEHLA_KADAM_QUS_GRID_LIST, GET_BRANCH_BY_ACTIVITY_ID,
   GET_BUSINESS_LEAD_SOURCE_OVERVIEW_DATA
} from '../../api/baseURL';
// import axios from 'axios' ;
import { isMeetindDate } from '../../utils/validators';
import { get, post, put, deleteReq, postIntegration, patch,postForBlob, getForBlob } from '../../../src/api/api'
import { activateErrorMessageBar, activateLoader, activateSuccessMessageBar, deActivateLoader } from '../../redux/actions';
import { logOutAction } from '../../redux/actions/userAction';
import { format } from "date-fns";
import { LEADS_PATH } from '../../route/routePath';
import { getFilteredPersistanceLeads, persistancyLeadListError, persistancyLeadListSuccess, persistancyActivity } from './persistancyLeadReducer';
import { campaignLeadListError, campaignLeadListSuccess, getFilteredCampaignLeads } from './campaignLeadReducer';
import { CAMPAIGNLEAD, PERSISTANCY } from '../../utils/config';
import {
    getPehlaKadamCheckListQus, getCheckListSaveResponse, setBDMChecklist, setBDMData,
    getPehlaKdamDashbordOverview, setExportDataFile, setBDMView,setBDMViewProfile,getPehlaKadamGridQus
} from './pehlaKadamReducer';
import { DownloadFiles } from '../../utils/utils';


const createformat = "YYYY-MM-DD HH:mm:ss";
export const createLeadReducer = () => async dispatch => {
    dispatch(createLeadLoading());
    try {
        await post("/")
            .then(response => dispatch(createLeadSuccess(response.data)));
    } catch (e) {
        return dispatch(createLeadError(e.message))
    }
};


export const getBranchValueReducer = (userId) => async dispatch => {
    try {
        await get(`${GET_BRANCH_DETAILS}/${userId}`)
            .then(response => {
                dispatch(getBranchValue(response.data));
            })
    } catch (e) {
        return console.error(e);
    }
};


export const getLGValueReducer = (eventTargetedValue) => async dispatch => {
    dispatch(activateLoader());
    try {
        await get(`${GET_LG_SP_DETAILS}/${eventTargetedValue}`)
            .then(response => {
                dispatch(getLGValue(response.data))
                dispatch(deActivateLoader())
            });
    } catch (e) {
        return console.error(e);
    }
};


export const getSelectSpReducer = (eventTargetedValue) => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"sp?lg="}${eventTargetedValue}`)
            .then(response => dispatch(getSelectSP(response.data)));
    } catch (e) {
        return console.error(e);
    }
};
export const getProductDetails = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"ProductDetails"}`)
            .then(response => {
                dispatch(getProducts(response.data))
            })
    } catch (e) {
        return console.error(e);
    }
};
export const getSalutationsDetails = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"Salutation"}`)
            .then(response => {
                dispatch(getSalutations(response.data))
            })
    } catch (e) {
        return console.error(e);
    }
};
export const getSPTypeDetails = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"SPType"}`)
            .then(response => {
                dispatch(getSPType(response.data))
            })
    } catch (e) {
        return console.error(e);
    }
};

export const getLeadTypeDetails = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"LeadType"}`)
            .then(response => {
                dispatch(getLeadType(response.data))
            })
    } catch (e) {
        return console.error(e);
    }
};

export const saveLeadForm = (payload) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        /**This condition is added for avoiding the entries in DB for CreatedBy & Modified BY as 0 */
        const userData = JSON.parse(localStorage.getItem('persist:root'));
        let userId;
        if (userData) {
            userId = JSON.parse(userData.userReducer).profileInfo.userId;
        }
        if (!userId || userId === 0 || userId === "0") {
            dispatch(activateErrorMessageBar('Your userId is not set'))
            return;
        }
        const data = {
            ...payload.data,
            "CreatedDate": moment(new Date()).format(createformat),
            // "CreatedBy": userId,
            "ModifiedDate": moment(new Date()).format(createformat),
            "ModifiedBy": userId,
            "SourceName": "ACTIFY"
        }
        let formdata = {
            "jsondata": JSON.stringify(data)
        }
        try {
            const res = await post(ADD_DYNAMIC_DATA + 'newlead', formdata)
            if (res.data.Responsestatus) {
                const { data } = res
                const { Transactionid } = data
                if (payload.leadScoreRequest) {
                    dispatch(leadScoringLMS(Transactionid, payload, payload.leadScoreRequest));
                }/** This will be called only in case of BANCA */
                resolve(res)

            } else {
                resolve(res)
            }
        } catch (err) {
            reject(err)
        }
    })
};

export const UpdateLeadForm = (payload) => async (dispatch, getState) => {
    /**This condition is added for avoiding the entries in DB for CreatedBy & Modified BY as 0 */
    const userData = JSON.parse(localStorage.getItem('persist:root'));
    let userId;
    if (userData) {
        userId = JSON.parse(userData.userReducer).profileInfo.userId;
    }
    if (!userId || userId === 0 || userId === "0") {
        dispatch(activateErrorMessageBar('Your userId is not set'))
        return;
    }
    const { model, history, Drafted } = payload
    loadingBegins();
    dispatch(activateLoader())
    model.ModifiedDate = moment(new Date()).format(createformat);
    model.CreatedDate = model.CreatedDate ? model.CreatedDate.toString().replace("T", " ").replace("Z", "") : null;
    model.MeetingStartDateTime = model.MeetingStartDateTime ? model.MeetingStartDateTime.toString().replace("T", " ").replace("Z", "") : null;
    model.MeetingEndDateTime = model.MeetingEndDateTime ? model.MeetingEndDateTime.toString().replace("T", " ").replace("Z", "") : null;
    model.ModifiedBy = userId;
    model['SourceName'] = model?.SourceName ? model.SourceName : "ACTIFY"
    let formdata = {
        "jsondata": JSON.stringify(model)
    }
    try {
        await put(UPDATE_DYNAMIC_DATA + 'existinglead', formdata).then(response => {
            dispatch(updateLeadSuccess(response.data));
            dispatch(deActivateLoader())
            dispatch(activateSuccessMessageBar(`${Drafted ? Drafted : 'Lead'} has been updated successfully`))
            // if lead status is INTRESTED, call the Simplify api

            if (model.LeadStatusId === 10) {
                switch (model.ChannelID) {
                    case 1:
                        dispatch(simplifyCreate(model, history));
                        break;
                    default:
                        const { data } = response
                        const { Transactionid, Responsestatus } = data
                        if (Responsestatus && Transactionid !== 0) {
                            history.push(`/app/lead/detail/${Transactionid}`)
                        } else {
                            dispatch(activateErrorMessageBar('Something went wrong...'))
                        }
                        break;
                }
            } else {
                const { data } = response
                const { Transactionid, Responsestatus } = data
                if (Responsestatus && Transactionid !== 0) {
                        history.push(`/app/lead/detail/${Transactionid}`)
                } else {
                    dispatch(activateErrorMessageBar('Something went wrong...'))
                }
            }
        })
    } catch (e) {
        return (
            dispatch(activateErrorMessageBar("e"))
        )
    }
    finally {
        LoadingEnd()
    }
}

export const UpdatePersistancyLeadForm = (payload) => async (dispatch, getState) => {
    const { model, history } = payload
    loadingBegins();
    dispatch(activateLoader())
    model.ModifiedDate = moment(new Date()).format(createformat);

    model.CreatedDate = model.CreatedDate ? model.CreatedDate.toString().replace("T", " ").replace("Z", "") : null;
    model.MeetingStartDateTime = model.MeetingStartDateTime ? model.MeetingStartDateTime.toString().replace("T", " ").replace("Z", "") : null;
    model.MeetingEndDateTime = model.MeetingEndDateTime ? model.MeetingEndDateTime.toString().replace("T", " ").replace("Z", "") : null;
    const userData = JSON.parse(localStorage.getItem('persist:root'));
    let userId;
    if (userData) {
        userId = JSON.parse(userData.userReducer).profileInfo.userId;
    }
    if (!userId || userId === 0 || userId === "0") {
        dispatch(activateErrorMessageBar('Your userId is not set'))
        return;
    }
    model.ModifiedBy = userId;
    let formdata = {
        "jsondata": JSON.stringify(model)
    }
    try {
        await put(UPDATE_DYNAMIC_DATA + 'existingpersistencylead', formdata).then(response => {
            dispatch(updateLeadSuccess(response.data));
            dispatch(deActivateLoader())
            dispatch(activateSuccessMessageBar('Lead has been updated successfully'))
            // if lead status is INTRESTED, call the Simplify api
            if (model.LeadStatusId === 10) {
                dispatch(simplifyCreate(model, history));
            } else {
                const { data } = response
                const { Transactionid } = data
                history.push(`/app/persistencyLead/detail/${Transactionid}`)
            }
        })
    } catch (e) {
        return (
            dispatch(activateErrorMessageBar("e"))
        )
    }
    finally {
        LoadingEnd()
    }
}

export const UpdateCampaignLeadForm = (payload) => async (dispatch, getState) => {
    const { model, history } = payload
    loadingBegins();
    dispatch(activateLoader())
    model.ModifiedDate = moment(new Date()).format(createformat);
    model.CreatedDate = model.CreatedDate ? model.CreatedDate.toString().replace("T", " ").replace("Z", "") : null;
    model.MeetingStartDateTime = model.MeetingStartDateTime ? model.MeetingStartDateTime.toString().replace("T", " ").replace("Z", "") : null;
    model.MeetingEndDateTime = model.MeetingEndDateTime ? model.MeetingEndDateTime.toString().replace("T", " ").replace("Z", "") : null;
    const userData = JSON.parse(localStorage.getItem('persist:root'));
    let userId;
    if (userData) {
        userId = JSON.parse(userData.userReducer).profileInfo.userId;
    }
    if (!userId || userId === 0 || userId === "0") {
        dispatch(activateErrorMessageBar('Your userId is not set'))
        return;
    }
    model.ModifiedBy = userId;
    let formdata = {
        "jsondata": JSON.stringify(model)
    }
    try {
        await put(UPDATE_DYNAMIC_DATA + 'existingcampaignlead', formdata).then(response => {
            dispatch(updateLeadSuccess(response.data));
            dispatch(deActivateLoader())
            dispatch(activateSuccessMessageBar('Lead has been updated successfully'))
            // if lead status is INTRESTED, call the Simplify api
            if (model.LeadStatusId === 10) {
                dispatch(simplifyCreate(model, history, `/app/campaignLead/detail/${model.LeadCampaignID}`));
            } else {
                const { data } = response
                const { Transactionid } = data
                history.push(`/app/campaignLead/detail/${Transactionid}`)
            }
        })
    } catch (e) {
        return (
            dispatch(activateErrorMessageBar("e"))
        )
    }
    finally {
        LoadingEnd()
    }
}


export const simplifyCreate = (payload, history, url = LEADS_PATH) => async (dispatch, getState) => {
    dispatch(activateLoader())
    const request = {
        VymoRapidReq: {
            vymoLeadId: payload.LeadId ? payload.LeadId.toString() : payload.LeadCampaignID.toString(),
            rapidLeadId: "",
            contactType: "Individual",
            channelCode: payload.LeadSourceName,
            salutation: payload.Salutation ? `${payload.Salutation}${'.'}` : `${payload.SalutationName}${'.'}`, // Simplify needs salutation with . like Mr.
            lead_status: payload.PriorityName,
            firstName: payload.FirstName,
            lastName: payload.LastName,
            dob:payload.leadInterestedDOB?payload.leadInterestedDOB:"", 
            mobile: payload.MobileNo,
            email: payload.Email,
            leadOwnerCode: payload.EmployeeCode.toString(),
            leadCreatorCode: payload.EmployeeCode.toString(),
            leadPursuedByCode: payload.EmployeeCode.toString(),
            branchCode: payload?.BranchCode?.toString(),
            partnerAgentName: payload.BranchName,
            spCode: payload?.SPCode,
            lgCode: payload?.LGCode,
            clientId: "",
            crossSellLeadFlag: "false",
            uncontactable: ""
        }
    }
    await postIntegration(SIMPLIFY_CREATE_URL, request, FUNCTIONS_KEY_SIMPLIFY_CREATE)
        .then(response => {
            console.log('SIMPLIFY_CREATE ', response);
            dispatch(deActivateLoader())
            history.push(url);
        })
        .catch((error) => {
            console.log(error);
            dispatch(deActivateLoader())
            history.push(url);
        })
}
export const leadScoringLMS = (leadId, payload, leadScoreRequest) => async (dispatch, getState) => {
    dispatch(activateLoader())
    const { data } = payload;
    let lead_status;
    if (data.PriorityID === "1") {
        lead_status = "Hot";
    } else if (data.PriorityID === "2") {
        lead_status = "Warm";
    } else {
        lead_status = "Cold";
    }

    const request = {
        lead_code: leadId?.toString(),
        salutation: leadScoreRequest.salutationName,
        select_status: lead_status,
        email_id: data.Email,
        product: leadScoreRequest.productName,
        expected_premium: data.ExpectedPremium,
        activity: leadScoreRequest.activity,
        campaign: '',
        date: format(new Date(), 'yyyy/MM/dd'),// "2022/05/16",
        select_branch_code: '',
        branch_code_activity: leadScoreRequest.branchCodeActivity,
        bdm_code: data.EmployeeCode,
        lg_code: leadScoreRequest.lgCode,
    };
    await postIntegration(LEAD_PRIORITY_URL, request, FUNCTIONS_KEY_LEAD_PRIORITY)
        .then(response => {
            console.log(response);
            dispatch(deActivateLoader())
        })
        .catch((error) => {
            console.log(error);
            dispatch(deActivateLoader())
        })
}
export const getUploadedImage = async (url) => {
    return new Promise((resolve, reject) => {
        getForBlob(`${DOWNLOAD_IMAGE_URL}${'/'}${url}`).then((response) => {
            resolve(response)
        })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    })
}
const getAutoCompleteItems = (autoCompleteItemsArr) => {
    let options = [];
    if (autoCompleteItemsArr && autoCompleteItemsArr.length > 0) {
        options = autoCompleteItemsArr.map((ac, idx) => {
            return {
                "id": ac.ActivityID.toString(),
                "label": `${ac.ActivityName ? ac.ActivityName : ""}${ac.BranchName ? `- ${ac.BranchName}` : ""}${ac.BANKNAME ? `-${ac.BANKNAME}` : ""}${ac.MeetingStartDateTime ? `-${moment(ac.MeetingStartDateTime).format('DD/MM/YYYY')}` : ""}`,
                "activitytypeid": ac.ActivitytypeID,
                "campaigningDoneId": ac?.CampaigningDoneId,
                "campaigningDoneName": ac?.CampaigningDoneName,
                "bankName": ac?.BANKNAME,
                "activityName": ac?.ActivityName,
                "activityStatus": ac?.ActivityStatus,
            }
            // return { "id": idx.toString(), "label": `${ac.ActivityName}-${ac.BranchName}-${ac.BANKNAME}-${moment(ac.MeetingStartDateTime).format('DD/MM/YYYY')}` }
        })
    }
    options.unshift({ "id": "", "label": "select" })
    return options
};
export const getAutoCompleteActivities = (payload) => async dispatch => {
    try {
        await get(`${GET_ACTIVITY_BY_USER_ID}/${payload.userId}`).then(response => {
            if (response.status === 200) {
                const autoCompleteItems = getAutoCompleteItems(response.data)
                dispatch(getActivities({ ActivityID: autoCompleteItems }))
            }
        })
    } catch (e) {
        return console.error(e);
    }

};

export const getAutoCompleteCampaignActivities = (payload) => async dispatch => {
    try {
        await get(`${GET_CAMPAIGN_ACTIVITY_BY_USER_ID}/${payload.userId}`).then(response => {
            if (response.status === 200) {
                const autoCompleteItems = getAutoCompleteItems(response.data)
                dispatch(getCampaignActivities({ ActivityID: autoCompleteItems }))
            }
        })
    } catch (e) {
        return console.error(e);
    }
}
export const getLeadSourceList = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"LeadSource"}`)
            .then(response => {
                dispatch(getLeadSource(response.data))
            })
    } catch (e) {
        return console.error(e);
    }
};

export const getZMAList = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"ZMADetails"}`)
            .then(response => {
                dispatch(getZMA(response.data))
            })
    } catch (e) {
        return console.error(e);
    }

};
export const getVehicleTypeList = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"VehicleType"}`)
            .then(response => dispatch(getVehicalType(response.data)));
    } catch (e) {
        return console.error(e);
    }
};
export const ModeofTransportList = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"ModeofTransport"}`)
            .then(response => dispatch(getTransport(response.data)));
    } catch (e) {
        return console.error(e);
    }
}
export const getCallBackReasonList = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"PersistencyCallBackReason"}`)
            .then(response => {
                dispatch(getCallBackReason(response.data))
            });
    } catch (e) {
        return console.error(e);
    }
};
export const getFollowupReasonList = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"FollowupReason"}`)
            .then(response => {
                dispatch(getFollowUpReason(response.data))
            })
    } catch (e) {
        return console.error(e);
    }
};
export const getRescheduleReasonList = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"RescheduleReason"}`)
            .then(response => {
                dispatch(getReschduleReason(response.data))
            })
    } catch (e) {
        return console.error(e);
    }
};
export const getDroppedReasonList = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"droppedreason"}`)
            .then(response => {
                dispatch(getDroppedReason(response.data))
            })
    } catch (e) {
        return console.error(e);
    }
};
export const getGCLCallDurationList = () => async dispatch => {
    try {
        const res = await get(`${GET_MASTER_DATA}${"GCLCallDuration"}`)
            .then(response => {
                dispatch(getGCLCallDuration(response.data))
                return response.data
            })
        return res
    } catch (e) {
        return console.error(e);
    }
};
export const getMeetingOptionList = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"FollowupReason"}`)
            .then(response => dispatch(getDroppedReason(response.data)));
    } catch (e) {
        return console.error(e);
    }
};
export const getExpenseTypeList = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${"ExpenseType"}`)
            .then(response => {
                dispatch(getExpenseType(response.data))
            })
    } catch (e) {
        return console.error(e);
    }
};


export const validateMeetingDate = (currentDate, LeadId) => async dispatch => {
    try {
        await get(`${VALIDATE_METING}/${LeadId}/${currentDate}`)
            .then(response => {
                const { Responsemessage } = response?.data;
            })
    } catch (e) {
        return console.error(e);
    }
};


export const validateIsCheckin = (payload) => (dispatch, getState) => {
    dispatch(activateLoader());
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get(`${VALIDATE_CHECKIN}/${payload.checkinFor}/${payload.Id}`)
            resolve(res)
            dispatch(deActivateLoader());
            const { Responsemessage } = res?.data;
            isMeetindDate(Responsemessage);
        } catch (err) {
            dispatch(deActivateLoader());
            reject(err)
        }
    })
}


export const listLead = (id, pageNo, NoOfRecordperPage) => async dispatch => {
    try {
        await get(`${GET_LEAD_BY_USERID}/${id}/${pageNo}/${NoOfRecordperPage}`)
            .then(response => {
                dispatch(getLeadList(response.data))
            })

    } catch (e) {
        return console.error(e);
    }
}

export const listPersistancyLead = (payload) => async dispatch => {
    try {
        const { UserId, PageNo, NoOfRecordperPage } = payload
        await get(`${GET_PERSISTANCY_LEAD_BY_USERID}/${UserId}/${PageNo}/${NoOfRecordperPage}`)
            .then(response => {
                dispatch(getPersistancyLeadList(response.data))
            })

    } catch (e) {
        return console.error(e);
    }
}

export const listCampaignLead = (payload) => async dispatch => {
    try {
        const { UserId, PageNo, NoOfRecordperPage } = payload
        await get(`${GET_CAMPAIGN_LEAD_BY_USER_ID}/${UserId}/${PageNo}/${NoOfRecordperPage}`)
            .then(response => {
                dispatch(getCampaignLeadList(response.data))
            })

    } catch (e) {
        return console.error(e);
    }
}

export const getPersistancyLeadDraftListFilter =
    (userID, pageNo, noOfRecPerPage, draftReq) => async (dispatch) => {
        dispatch(activateLoader());
        try {
            await post(
                `${GET_PERSISTANCY_LEAD_FILTER}/${userID}/${pageNo}/${noOfRecPerPage}`,
                draftReq
            ).then((response) => {
                console.log(response.data, "draftreducerlistdata");
                LoadingEnd();
                dispatch(getPersistancyLeadDraftListData(response.data));
                dispatch(deActivateLoader());
            });
        } catch (e) {
            return console.error(e);
        }
    };


// export const getCampaignLeadDraftListFilter =
//     (userID, pageNo, noOfRecPerPage, draftReq) => async (dispatch) => {
//         dispatch(activateLoader());
//         try {
//             await post(
//                 `${GET_CAMPAIGN_LEAD_FILTER}/${userID}/${pageNo}/${noOfRecPerPage}`,
//                 draftReq
//             ).then((response) => {
//                 console.log(response.data, "draftreducerlistdata");
//                 LoadingEnd();
//                 const { TotalnoofRows, Noofpages, GetactivitybyactivitybyUserID } =
//                     response.data;
//                 const res = {
//                     Listofleads: GetactivitybyactivitybyUserID,
//                     Noofpages: Noofpages,
//                     TotalnoofRows: TotalnoofRows,
//                 };
//                 dispatch(getCampaignLeadDraftListData(response.data));
//                 dispatch(deActivateLoader());
//             });
//         } catch (e) {
//             return console.error(e);
//         }
//     };



export const getLeadListById = (id) => async dispatch => {
    dispatch(activateLoader());
    try {
        await get(`${GET_LEAD_BY_ID}/${id}`)
            .then(response => {
                dispatch(getLeadByIdData(response?.data))
                dispatch(deActivateLoader());
            })

    } catch (e) {
        return console.error(e);

    }

}

export const getSelectedDraftLeadData = (id) => (dispatch, getState) => {
    dispatch(activateLoader())
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get(`${GET_LEAD_BY_ID}/${id}`)
            dispatch(deActivateLoader)
            resolve(res.data)
        } catch (err) {
            dispatch(deActivateLoader)
            reject(err)
        }
    })
}


export const getPersistancyLeadListById = (id) => async dispatch => {
    dispatch(activateLoader());
    try {
        await get(`${GET_PERSISTANCY_LEAD_BY_ID}/${id}`)
            .then(response => {
                dispatch(getPersistancyLeadByIdData(response?.data))
                dispatch(deActivateLoader());
            })
    } catch (e) {
        return console.error(e);

    }

}


export const getCampaginLeadListById = (id) => async dispatch => {
    dispatch(activateLoader());
    try {
        await get(`${GET_COMPAIGN_LEAD_BY_ID}/${id}`)
            .then(response => {
                dispatch(getCampaginLeadByIdData(response?.data))
                dispatch(deActivateLoader());
            })
    } catch (e) {
        return console.error(e);

    }

}

export const getPersistancyLeadByID = (payload) => (dispatch, getState) => {

    dispatch(activateLoader())

    return new Promise(async (resolve, reject) => {

        try {

            const res = await get(`${GET_PERSISTANCY_LEAD_BY_ID}/${payload.id}`)

            resolve(res)

        } catch (err) {

            reject(err)

        }

    })

}

export const getCampaignLeadListById = (id) => async dispatch => {
    dispatch(activateLoader());
    try {
        await get(`${GET_COMPAIGN_LEAD_BY_ID}/${id}`)
            .then(response => {
                dispatch(getCampaginLeadByIdData(response?.data))
                dispatch(deActivateLoader());
            })
    } catch (e) {
        return console.error(e);

    }

}

export const getCompaignLeadByID = ({ id }) => (dispatch, getState) => {
    dispatch(activateLoader())
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get(`${GET_COMPAIGN_LEAD_BY_ID}/${id}`)
            resolve(res)
        } catch (err) {
            reject(err)
        }
    })

}

export const validateMeetingDateStatus = (payload) => async dispatch => {
    const { startDate, endDate, id, formData, formBuilder, history, leadType } = payload
    dispatch(activateLoader());
    try {
        await get(`${VALIDATE_MEETING_DATE}/${id}/${startDate}/${endDate}/${leadType}`)
            .then(response => {
                const { Responsemessage, Responsestatus } = response.data;
                if (Responsestatus) {
                    dispatch(UpdateLeadForm({ model: formData, formBuilder: formBuilder, history: history }));
                } else {
                    dispatch(activateErrorMessageBar(Responsemessage));
                }
                dispatch(deActivateLoader());
            })
    } catch (e) {
        dispatch(deActivateLoader());
        return console.error(e);
    }

}

export const validateMeetingDateStatusPersistancy = (payload) => async dispatch => {
    const { startDate, endDate, id, formData, formBuilder, history, leadType } = payload
    let leadTypeId = (leadType === PERSISTANCY) ? 2 : 3;
    dispatch(activateLoader());
    try {
        await get(`${VALIDATE_MEETING_DATE}/${id}/${startDate}/${endDate}/${leadTypeId}`)
            .then(response => {
                const { Responsemessage } = response.data;
                if (Responsemessage === 'Success') {
                    if (leadType === PERSISTANCY) {
                        dispatch(UpdatePersistancyLeadForm({ model: formData, formBuilder: formBuilder, history: history }));
                    }
                    if (leadType === CAMPAIGNLEAD) {
                        dispatch(UpdateCampaignLeadForm({ model: formData, formBuilder: formBuilder, history: history }));
                    }
                } else {
                    dispatch(activateErrorMessageBar(Responsemessage));
                }
                dispatch(deActivateLoader());
            })

    } catch (e) {
        dispatch(deActivateLoader());
        return console.error(e);
    }

}
export const validateMeetingDateForCreateLead = (payload) => (dispatch, getState) => {
    dispatch(activateLoader())
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get(`${VALIDATE_MEETING_DATE}/${payload.id}/${payload.startDate}/${payload.endDate}/${payload.leadTypeId}`)
            dispatch(deActivateLoader)
            resolve(res)
        } catch (err) {
            dispatch(deActivateLoader)
            reject(err)
        }
    })

}
export const validateMeetingDateStatusInCreate = (payload) => async dispatch => {
    try {
        await get(`${VALIDATE_MEETING_DATE}/${payload.id}/${payload.startDate}/${payload.endDate}`)
            .then(response => {
                const { Responsemessage, Responsestatus } = response.data;
                dispatch(getMeetingStatus(Responsestatus))
                if (Responsemessage === 'Success' && Responsestatus) {
                    if (payload.leadId) {
                        dispatch(UpdateLeadForm({ model: payload.formData, formBuilder: payload.formBuilder, history: payload.history }))
                    } else {
                        dispatch(saveLeadForm({ data: payload.formData, formName: payload.formBuilder, history: payload.history, Responsestatus: Responsestatus, meetingStatus: Responsestatus }));
                    }
                }
            })

    } catch (e) {
        dispatch(activateErrorMessageBar("Other meeting scheduled on the same time"))
    }

}

export const updateLeadByStatus = (leadId, statusId, formdata) => async dispatch => {
    loadingBegins()

    try {
        await put(`${UPDATE_LEAD_SATUS_BY_ID}/${leadId}/${statusId}`, formdata).then(response => {
            dispatch(updateLeadStatusAction(response.data))
        })
    } catch (e) {
        return console.error(e);
    }
    finally {
        LoadingEnd()
    }

}

export const getNextLeadByStatus = (leadId, Type) => async dispatch => {
    loadingBegins()
    try {
        await get(`${GET_LEAD_STATUS_ACTION}/${leadId}/${Type}`).then(response => {

            dispatch(getLeadAction(response.data))
        })
    } catch (e) {
        dispatch(getLeadAction([]))
        return console.error(e);
    }
    finally {
        LoadingEnd()
    }
}

const getAutoCompleteTeamItems = (autoCompleteItemsArr) => {
    let options = [];
    if (autoCompleteItemsArr && autoCompleteItemsArr.length > 0) {
        options = autoCompleteItemsArr.map((ac) => {
            return { id: ac.UserID, label: ac.UserName }
        })
    }
    return options
};

export const getTeamList = (userId) => async dispatch => {
    loadingBegins()
    try {
        await get(`${GET_TEAM_LIST}/${userId}`).then(response => {
            if (response.status === 200) {
                const data = getAutoCompleteTeamItems(response.data);
                console.log('data', data);
                dispatch(getTeam({ AssignedTo: data }))
            }
        })
    } catch (e) {
        return console.error(e);
    }
    finally {
        LoadingEnd()
    }
}
export const getLeadStatusList = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${'leadstatus'}`)
            .then(response => dispatch(getLeadStatus(response.data)));
    } catch (e) {
        return console.error(e);
    }
}
export const getFilterLeadList = (userID, pageNo, noOfRecPerPage, filterReq, selectedFilter, viewForStatus, teamMemberID) => async dispatch => {
    const { roleId, viewFor } = selectedFilter
    dispatch(activateLoader());
    try {
        await
            post(`${GET_FILTER_LEAD_BY_USERID}/${userID}/${pageNo}/${noOfRecPerPage}?rolename=${roleId ? roleId : "ABH"}&viewFor=${viewForStatus !== "" ? viewForStatus : (viewFor ? viewFor : "SELF")}&subordinateUserIds=${teamMemberID ? teamMemberID : 'all'}`, filterReq)
                .then(response => {
                    LoadingEnd()
                    const { TotalnoofRows, Noofpages, GetactivitybyactivitybyUserID } = response.data
                    const res = {
                        Listofleads: GetactivitybyactivitybyUserID,
                        Noofpages: Noofpages,
                        TotalnoofRows: TotalnoofRows
                    }
                    dispatch(getLeadList(res))
                    dispatch(deActivateLoader())
                });
    } catch (e) {
        return console.error(e);
    }
}

export const getLeadHistory = (id, Type) => async dispatch => {
    loadingBegins()
    try {
        await get(`${GET_LEAD_HISTORY}/${id}?Historyfor=${Type}`).then(response => {
            if (response.status === 200) {
                dispatch(getLeadDetailsHistory(response.data))
            }
        })
    } catch (e) {
        dispatch(getLeadDetailsHistory([]))
        return console.error(e);
    }
    finally {
        LoadingEnd()
    }
}
export const getLeadDetails = (payload) => (dispatch, getState) => {
    dispatch(activateLoader())
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get(`${GET_LEAD_BY_ID}/${payload.id}`)
            resolve(res)
        } catch (err) {
            reject(err)
        }
    })
}

export const checkMobileNumberReducer = (payload) => dispatch => {
    const { mobileNumber, userId } = payload
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get(`${VALIDATE_LEAD_MOBILE}/${mobileNumber}/${userId}`)
            const { Responsestatus } = res?.data;
            resolve(Responsestatus)
        } catch (err) {
            reject(err)
        }
    })
};

export const getBranchDetailsFromActivityId = (payload) => dispatch => {
    const { activityId} = payload
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get(`${GET_BRANCH_BY_ACTIVITY_ID}${activityId}`)
            resolve(res)
        } catch (err) {
            reject(err)
        }
    })
};


export const getLeadListByIdForTable = (payload, userID, roleId = "BDM", viewFor, teamMemberID) => async (dispatch) => {
    dispatch(activateLoader(true));

    try {
        const { UserId, PageNo, NoOfRecordperPage, filterReq } = payload
        await post(
            `${GET_FILTER_LEAD_BY_USERID}/${UserId}/${PageNo}/${NoOfRecordperPage}?rolename=${roleId}&viewFor=${viewFor !== "" ? viewFor : "SELF"}&subordinateUserIds=${teamMemberID ? teamMemberID : 'all'}`,
            filterReq
        ).then((response) => {
            LoadingEnd();
            const { TotalnoofRows, Noofpages, GetactivitybyactivitybyUserID } =
                response.data;
            const res = {
                Listofleads: GetactivitybyactivitybyUserID,
                Noofpages: Noofpages,
                TotalnoofRows: TotalnoofRows,
            };
            dispatch(getLeadList(res));
            dispatch(deActivateLoader(false));
        });
    } catch (e) {
        dispatch(getLeadDetailsForTable([]));
        return console.error(e);
    } finally {
        LoadingEnd();
    }
};
export const getCampaignLeadListByIdForTable = (payload, userID, roleId = "BDM", viewFor, teamMemberID) => async (dispatch) => {
    dispatch(activateLoader(true));
    try {
        const { PageNo, NoOfRecordperPage, filterReq } = payload
        await post(
            `${GET_CAMPAIGN_LEAD_FILTER}/${userID}/${PageNo}/${NoOfRecordperPage}?rolename=${roleId}&viewFor=${viewFor !== "" ? viewFor : "SELF"}&subordinateUserIds=${teamMemberID ? teamMemberID : 'all'}`,
            filterReq
        ).then((response) => {
            LoadingEnd();
            const { TotalnoofRows, Noofpages, Campaignleaddata } =
                response.data;
            const res = {
                Campaignleaddata,
                Noofpages: Noofpages,
                TotalnoofRows: TotalnoofRows,
            };
            dispatch(campaignLeadListSuccess(res));
            dispatch(deActivateLoader(false));
        });
    } catch (e) {
        return console.error(e);
    } finally {
        LoadingEnd();
    }
};


export const getPersistancyLeadListByIdForTable = (payload) => async (dispatch) => {
    dispatch(activateLoader(true));

    try {
        const { UserId, PageNo, NoOfRecordperPage, filterReq } = payload
        await post(
            `${GET_PERSISTANCY_LEAD_BY_USERID}/${UserId}/${PageNo}/${NoOfRecordperPage}`,
            filterReq
        ).then((response) => {
            LoadingEnd();
            const { TotalnoofRows, Noofpages, GetactivitybyactivitybyUserID } =
                response.data;
            const res = {
                Listofleads: GetactivitybyactivitybyUserID,
                Noofpages: Noofpages,
                TotalnoofRows: TotalnoofRows,
            };
            dispatch(getPersistancyLeadList(res));
            dispatch(deActivateLoader(false));
        });
    } catch (e) {
        dispatch(getPersistancyLeadDetailsForTable([]));
        return console.error(e);
    } finally {
        LoadingEnd();
    }
};

export const getLeadDraftList =
    (userID, pageNo, noOfRecPerPage, draftReq, roleId = "BDM", viewFor, subordinateUserIds) => async (dispatch) => {
        console.log(subordinateUserIds);
        dispatch(activateLoader());
        try {
            await post(
                `${GET_FILTER_LEAD_BY_USERID}/${userID}/${pageNo}/${noOfRecPerPage}?rolename=${roleId}&viewFor=${viewFor !== "" ? viewFor : "SELF"}&subordinateUserIds=${subordinateUserIds ? subordinateUserIds : 'all'}`,
                draftReq
            ).then((response) => {
                console.log(response.data, "draftreducerlistdata");
                LoadingEnd();
                const { TotalnoofRows, Noofpages, GetactivitybyactivitybyUserID } =
                    response.data;
                const res = {
                    Listofleads: GetactivitybyactivitybyUserID,
                    Noofpages: Noofpages,
                    TotalnoofRows: TotalnoofRows,
                };
                dispatch(getLeadDraftListData(response.data));
                dispatch(deActivateLoader());
            });
        } catch (e) {
            return console.error(e);
        }
    };

export const getLeadBacklogList =
    (userID, pageNo, noOfRecPerPage, draftReq, selectedFilter, viewForStatus, teamMemberID) => async (dispatch) => {
        const { roleId, viewFor } = selectedFilter
        console.log("viewForStatus", viewForStatus, "viewFor", viewFor);
        dispatch(activateLoader(true));
        try {
            await post(
                `${GET_FILTER_LEAD_BY_USERID}/${userID}/${pageNo}/${noOfRecPerPage}?rolename=${roleId}&viewFor=${viewForStatus !== "" ? viewForStatus : (viewFor ? viewFor : "SELF")}&subordinateUserIds=${teamMemberID ? teamMemberID : 'all'}`,
                draftReq
            ).then((response) => {
                console.log("backlogdata", response.data);
                LoadingEnd();
                const { TotalnoofRows, Noofpages, GetactivitybyactivitybyUserID } =
                    response.data;
                const res = {
                    Listofleads: GetactivitybyactivitybyUserID,
                    Noofpages: Noofpages,
                    TotalnoofRows: TotalnoofRows,
                };
                dispatch(getLeadBacklogListData(response.data));
                dispatch(deActivateLoader(false));
            });
        } catch (e) {
            dispatch(deActivateLoader())
            return console.error(e);
        }
    };

export const getPersistancyLeadBacklogList =
    (userID, pageNo, noOfRecPerPage, BacklogReq, roleId = 'BDM', viewForStatus, teamMemberID, selectedFilter) => async (dispatch) => {
        dispatch(activateLoader(true));
        try {
            await post(
                `${GET_PERSISTANCY_LEAD_FILTER}/${userID}/${pageNo}/${noOfRecPerPage}?rolename=${roleId}&viewFor=${selectedFilter?.viewFor ? selectedFilter?.viewFor : (viewForStatus !== "" ? viewForStatus : "SELF")}&subordinateUserIds=${teamMemberID ? teamMemberID : userID}`,
                BacklogReq
            ).then((response) => {
                LoadingEnd();
                dispatch(getPersistancyLeadBacklogListData(response.data));
                dispatch(deActivateLoader());
            });
        } catch (e) {
            dispatch(deActivateLoader());
            return console.error(e);
        }
    };

export const getPersistencyLeadBacklogListFilter =
    (userID, pageNo, noOfRecPerPage, BacklogReq, selectedFilter, viewForStatus, teamMemberID) => async (dispatch) => {
        const { roleId, viewFor } = selectedFilter
        dispatch(activateLoader(true));
        try {
            await post(
                `${GET_PERSISTANCY_LEAD_FILTER}/${userID}/${pageNo}/${noOfRecPerPage}?rolename=${roleId ? roleId : "ABH"}&viewFor=${viewForStatus !== "" ? viewForStatus : (viewFor ? viewFor : "SELF")}&subordinateUserIds=${teamMemberID ? teamMemberID : userID}`,
                BacklogReq
            ).then((response) => {
                LoadingEnd();
                dispatch(getPersistancyLeadBacklogListData(response.data));
                dispatch(deActivateLoader());
            });
        } catch (e) {
            dispatch(deActivateLoader());
            return console.error(e);
        }
    };


export const getCampaignLeadBacklogList =
    (userID, pageNo, noOfRecPerPage, BacklogReq, roleId = 'BDM', viewForStatus, teamMemberID) => async (dispatch) => { //selectedtViewFor
        dispatch(activateLoader(true));
        try {
            await post(
                `${GET_CAMPAIGN_LEAD_FILTER}/${userID}/${pageNo}/${noOfRecPerPage}?rolename=${roleId}&viewFor=${viewForStatus !== "" ? viewForStatus : "SELF"}&subordinateUserIds=${teamMemberID ? teamMemberID : 'all'}`,
                BacklogReq
            ).then((response) => {
                LoadingEnd();
                dispatch(getCampaignLeadBacklogListData(response.data));
                dispatch(deActivateLoader());
            });
        } catch (e) {
            dispatch(deActivateLoader());
            return console.error(e);
        }
    };


export const getCampaignLeadBacklogListFilter =
    (userID, pageNo, noOfRecPerPage, BacklogReq, selectedFilter, viewForStatus, teamMemberID) => async (dispatch) => {
        const { roleId, viewFor } = selectedFilter
        dispatch(activateLoader(true));
        try {
            await post(
                `${GET_CAMPAIGN_LEAD_FILTER}/${userID}/${pageNo}/${noOfRecPerPage}?rolename=${roleId ? roleId : "ABH"}&viewFor=${viewForStatus !== "" ? viewForStatus : (viewFor ? viewFor : "SELF")}&subordinateUserIds=${teamMemberID ? teamMemberID : userID}`,
                BacklogReq
            ).then((response) => {
                LoadingEnd();
                dispatch(getCampaignLeadBacklogListData(response.data));
                dispatch(deActivateLoader());
            });
        } catch (e) {
            dispatch(deActivateLoader());
            return console.error(e);
        }
    };

export const deleteDraftListByID = (draftFor, draftIds) => async (dispatch) => {
    console.log(draftFor, draftIds, 'dekete')
    dispatch(activateLoader(true));
    try {
        await deleteReq(`${DELETE_LEAD_DRAFTLIST_BY_ID}/${draftFor}/${draftIds}`).then((response) => {
            dispatch(deActivateLoader(false))
            dispatch(activateSuccessMessageBar('Deleted Successfully'))
        })
    }
    catch (e) {
        dispatch(deActivateLoader());
        console.log(e)
    }
}

export const createLeadDeDupeValidation = (payload) => async (dispatch) => {
    const { UserId, MobileNo, FirstName, LastName, Email } = payload
    return new Promise(async (resolve, reject) => {
        try {
            dispatch(activateLoader());
            const res = await get(`${CREATE_LEAD_DEDUPE_VALIDATION_URL}/${UserId}/${MobileNo}/${FirstName}/${LastName}/${Email}`)
            dispatch(deActivateLoader());
            resolve(res?.data.Responsestatus)
        } catch (e) {
            reject(e)
            dispatch(deActivateLoader());
            dispatch(activateErrorMessageBar('lead details already exists for combination of first name, last name, email and mobile number'))
        }
    })
}
export const logoutUserReducer = () => async (dispatch) => {
    dispatch(logOutAction(null));
}

export const LeadOverviewDashboardData = (payload) => async (dispatch) => {
    dispatch(activateLoader(true));
    // return new Promise(async(resolve,reject)=>{
    try {
        const res = await post(`${GetLeadOverview}`, payload)
        dispatch(getLeadsOverviewData(res.data))
        // resolve(res.data)
        dispatch(deActivateLoader(false))
    } catch (e) {
        // reject(e)
        dispatch(deActivateLoader(false))
    }
    // }) 
}

export const LeadOverviewData = (payload) => async (dispatch) => {
    dispatch(activateLoader(true));
    try {
        const res = await post(`${GetLeadOverview}`, payload)
        console.log(res.data,res.data[0], 'responseleadoverview')
        dispatch(getLeadOverViewDashboardData(res.data[0]))
        dispatch(deActivateLoader(false))
    } catch (e) {
        dispatch(deActivateLoader(false))
    }
}
export const PersistancyLeadOverviewDashboardData = (payload) => async (dispatch) => {
    dispatch(activateLoader(true));
    try {
        const res = await post(`${GetPersistancyLeadOverview}`, payload)
        dispatch(getPersistancyLeadsOverviewData(res.data))
        // resolve(res.data)
        dispatch(deActivateLoader(false))
    } catch (e) {
        // reject(e)
        dispatch(deActivateLoader(false))
    }
    // }) 
}

export const PersistancyLeadOverviewData = (payload) => async (dispatch) => {
    dispatch(activateLoader(true));
    try {
        const res = await post(`${GetPersistancyLeadOverview}`, payload)
        console.log(res.data, 'responseleadoverview')
        dispatch(getPersistancyLeadOverViewDashboardData(res.data[0]))
        dispatch(deActivateLoader(false))
    } catch (e) {
        dispatch(deActivateLoader(false))
    }
}
export const PaymentMode = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${'PaymentType'}`)
            .then(response => dispatch(getPaymentType(response.data)));
    } catch (e) {
        return console.error(e);
    }
}

export const PaymentSubType = () => async dispatch => {
    try {
        await get(`${GET_MASTER_DATA}${'PaymentSubType'}`)
            .then(response => dispatch(getPaymentSubType(response.data)));
    } catch (e) {
        return console.error(e);
    }
}

export const getBDMforABHlist = (userId, roleName) => async dispatch => {
    let data = []
    try {
        await get(`${BDM_LIST}${userId}${'/'}${roleName}`)
            .then(response => {
                dispatch(bdmListForABH(response.data[0]))
                data = response
            })
    } catch (e) {
        return console.error(e);
    }
    return data
};

export const getTeamlist= (userId, roleName) => async dispatch => {
    let data = []
    try {
        await get(`${TEAM_LIST}${userId}${'/'}${roleName}`)
            .then(response => {
                dispatch(TeamList(response.data[0]))
                data = response
            })
    } catch (e) {
        return console.error(e);
    }
    return data
};

export const getABHByZBH = (userId, roleName) => async dispatch => {
    try {
        await get(`${SUBORDINATE_BY_USER_ID_AND_LEVEL}${userId}${'/'}${roleName}`)
            .then(response => {
                dispatch(ABHListForZBH(response.data === "User not found" ? [] : response.data))
            }).catch(err => {
                console.log(err)
            })
    } catch (e) {
        return console.error(e);
    }
};

export const getSecondLevelByUser = (userId, roleName) => async dispatch => {
    try {
        await get(`${SUBORDINATE_BY_USER_ID_AND_LEVEL}${userId}${'/'}${roleName}`)
            .then(response => {
                dispatch(secondLevelSubordinateListForAll(response.data === "User not found" ? [] : response.data))
            }).catch(err => {
                console.log(err)
            })
    } catch (e) {
        return console.error(e);
    }
};
export const getSecondLevelSubordinatelist = (userId, roleName) => async dispatch => {
    try {
        await get(`${BDM_LIST}${userId}${'/'}${roleName}`)
            .then(response => {
                dispatch(secondLevelSubordinateListForAll(response.data[0]))
            })
    } catch (e) {
        return console.error(e);
    }
};

export const getSecondLevelTeamlist = (userId, roleName) => async dispatch => {
    try {
        await get(`${TEAM_LIST}${userId}${'/'}${roleName}`)
            .then(response => {
                dispatch(secondLevelTeamListForAll(response.data[0]))
            })
    } catch (e) {
        return console.error(e);
    }
};
export const getThirdLevelSubordinatelist = (userId, roleName) => async dispatch => {
    try {
        await get(`${BDM_LIST}${userId}${'/'}${roleName}`)
            .then(response => {
                dispatch(thirdLevelSubordinateListForAll(response.data[0]))
            })
    } catch (e) {
        return console.error(e);
    }
};

export const getThirdLevelTeamlist = (userId, roleName) => async dispatch => {
    try {
        await get(`${TEAM_LIST}${userId}${'/'}${roleName}`)
            .then(response => {
                dispatch(thirdLevelTeamListForAll(response.data[0]))
            })
    } catch (e) {
        return console.error(e);
    }
};

export const getFourthLevelSubordinatelist = (userId, roleName) => async dispatch => {
    try {
        await get(`${BDM_LIST}${userId}${'/'}${roleName}`)
            .then(response => {
                dispatch(fourthLevelSubordinateListForAll(response.data[0]))
            })
    } catch (e) {
        return console.error(e);
    }
};

export const getFourthLevelTeamlist = (userId, roleName) => async dispatch => {
    try {
        await get(`${TEAM_LIST}${userId}${'/'}${roleName}`)
            .then(response => {
                dispatch(fourthLevelTeamListForAll(response.data[0]))
            })
    } catch (e) {
        return console.error(e);
    }
};
export const getactivitiesforpersistencyfilter = (userId) => async dispatch => {
    try {
        await get(`${PERSISTENCY_ACTIVITY_FILTER}${userId}`)
            .then(response => {
                console.log("activity", response.data);
                dispatch(persistancyActivity(response.data))
            })
    } catch (e) {
        return console.error(e);
    }
};
// persistancy 
export const getAllPersistancyLeadList = ({ UserId, PageNo, NoOfRecordperPage }) => async dispatch => {
    dispatch(activateLoader(true));
    try {
        await
            get(`${GET_ALL_PERSISTENCY_LEADS}/${UserId}/${PageNo}/${NoOfRecordperPage}`)
                .then(response => dispatch(persistancyLeadListSuccess(response.data)));
    } catch (e) {
        dispatch(persistancyLeadListError(e.message))
        return console.error(e);
    } finally {
        dispatch(deActivateLoader(false))
    }
}

// campaign 
export const getAllCampaignLeadList = ({ UserId, PageNo, NoOfRecordperPage }) => async dispatch => {
    dispatch(activateLoader(true));
    try {
        await
            get(`${GET_CAMPAIGN_LEAD_BY_USER_ID}/${UserId}/${PageNo}/${NoOfRecordperPage}`)
                .then(response => dispatch(campaignLeadListSuccess(response.data)));
    } catch (e) {
        dispatch(campaignLeadListError(e.message))
        return console.error(e);
    } finally {
        dispatch(deActivateLoader(false))
    }
}
export const getCampaignLeadCampaignNames = (payload) => async dispatch => {
    dispatch(activateLoader(true));
    try {
        await
            post(`${GET_CAMPAIGN_LEAD_CAMPAIGN_NAMES}`, payload)
                .then(response => {
                    console.log("response", response)
                    dispatch(getCampaignLeadCampaignNamesAction(response.data))
                });
    } catch (e) {
        // dispatch(campaignLeadListError(e.message))
        return console.error(e);
    } finally {
        dispatch(deActivateLoader(false))
    }
}

export const getCampaignLeadOverviewData = (payload) => async (dispatch) => {
    dispatch(activateLoader(true));
    try {
        const res = await post(`${GET_CAMPAIGN_DASHBOARD_OVERVIEW_DATA}`, payload)

        console.log(res.data, 'responseleadoverview')
        dispatch(setCampaingLeadDashboardOverview(res.data[0]))
        dispatch(deActivateLoader(false))
    } catch (e) {
        dispatch(deActivateLoader(false))
    }
}




export const getFilteredPersistancyLeadList = (UserId, PageNo, NoOfRecordperPage, filterReq, selectedFilter, viewForStatus, teamMemberID) => async dispatch => {
    console.log('appi', viewForStatus)
    const { roleId, viewFor } = selectedFilter
    dispatch(activateLoader(true));
    try {
        await
            post(`${PERSISTENCY_LEAD_FILTER}/${UserId}/${PageNo}/${NoOfRecordperPage}?rolename=${roleId ? roleId : "ABH"}&viewFor=${viewForStatus !== "" ? viewForStatus : (viewFor ? viewFor : "SELF")}&subordinateUserIds=${teamMemberID ? teamMemberID : "all"}`, filterReq)
                .then(response => {
                    dispatch(getFilteredPersistanceLeads(response.data))

                });
    } catch (e) {
        dispatch(persistancyLeadListError(e.message))
        return console.error(e);
    } finally {
        dispatch(deActivateLoader(false))
    }
}







export const getFilteredCampaignLeadList = (UserId, PageNo, NoOfRecordperPage, filterReq, selectedFilter, viewForStatus, teamMemberID) => async dispatch => {
    const { roleId, viewFor } = selectedFilter
    dispatch(activateLoader(true));
    try {
        await
            post(`${GET_CAMPAIGN_LEAD_FILTER}/${UserId}/${PageNo}/${NoOfRecordperPage}?rolename=${roleId ? roleId : "ABH"}&viewFor=${viewForStatus !== "" ? viewForStatus : (viewFor ? viewFor : "SELF")}&subordinateUserIds=${teamMemberID ? teamMemberID : UserId}`, filterReq)
                .then(response => {
                    dispatch(getFilteredCampaignLeads(response.data))

                });
    } catch (e) {
        dispatch(campaignLeadListError(e.message))
        return console.error(e);
    } finally {
        dispatch(deActivateLoader(false))
    }
}

export const ReAssignLeadToBDM = (payload) => async dispatch => {
    const { LeadID, UserId, LeadType, BDMName, history } = payload
    dispatch(activateLoader(true));
    try {
        await patch(`${REASSIGN_LEADS}/${LeadID}/${UserId}/${LeadType}`)
        dispatch(activateSuccessMessageBar(`Lead has been Assigned to ${BDMName} successfully`))
        history.goBack();
    } catch (e) {

    } finally {
        dispatch(deActivateLoader(false))
    }
}

export const getBDMByBranchID = (userID, BranchID = 0) => async (dispatch, getState) => {

    return new Promise(async (resolve, reject) => {
        dispatch(activateLoader())

        try {

            const res = await get(`${GET_SUBORDINATES_LIST}${userID}?BranchID=${BranchID}`)

            resolve(res.data)
            dispatch(deActivateLoader)
        } catch (err) {
            reject(err)
            dispatch(deActivateLoader())

        }
        finally {
            dispatch(deActivateLoader())
        }
    })

}


export const getSearchLeadData = (Id, inputValue) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        dispatch(activateLoader())
        try {
            const res = await get(`${SEARCH_LEAD}/${Id}/${inputValue}`)
            resolve(res)
            dispatch(getSearchLeadList(res?.data))
            dispatch(deActivateLoader())
        } catch (err) {
            reject(err)
        }
    })
}
export const sendSMSTwilio = (payload) => async dispatch => {
    dispatch(activateLoader(true));
    try {
        await
            post(`${SEND_SMS_TWILIO}`, payload)
                .then(response => {
                    console.log("sendSMSTwilio", response.data);
                    dispatch(activateSuccessMessageBar('Your SMS has been sent'))
                });
    } catch (e) {
        return console.error(e);
    } finally {
        dispatch(deActivateLoader(false))
    }
}

export const getCampaignFilterNames = (UserId) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        dispatch(activateLoader())
        try {
            const res = await get(`${GET_CAMPAIGN_NAME_FOR_FILTER}/${UserId}`)
            resolve(res)
            dispatch(getCampaginNamesData(res.data))
            dispatch(deActivateLoader())
        } catch (err) {
            reject(err)
        }
    })

}

export const getProfessionData = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get(`${GET_MASTER_DATA}${"Profession"}`)
            dispatch(deActivateLoader())
            resolve(res?.data)

        } catch (err) {
            reject(err)
            dispatch(deActivateLoader())
        }
    })
}
export const getEngagementData = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get(`${GET_MASTER_DATA}${"Engagement"}`)
            dispatch(deActivateLoader())
            resolve(res?.data)

        } catch (err) {
            reject(err)
            dispatch(deActivateLoader())
        }
    })
}
export const getIncomeGroupDetails = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get(`${GET_MASTER_DATA}${"IncomeGroupDetails"}`)
            dispatch(deActivateLoader())
            resolve(res?.data)

        } catch (err) {
            reject(err)
            dispatch(deActivateLoader())
        }
    })
}
export const getRecruitmentLeadType = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get(`${GET_MASTER_DATA}${"leadtype"}`)
            dispatch(deActivateLoader())
            resolve(res?.data)

        } catch (err) {
            reject(err)
            dispatch(deActivateLoader())
        }
    })
}
export const BusinessLeadOverviewDashboardData = (payload) => async (dispatch) => {
    dispatch(activateLoader(true));
    try {
        const res = await post(`${GetLeadOverview}`, payload)
        dispatch(getBusinessLeadsOverviewData(res.data))
        dispatch(deActivateLoader(false))
    } catch (e) {
        dispatch(deActivateLoader(false))
    }
}

export const BusinessLeadOverviewData = (payload) => async (dispatch) => {
    dispatch(activateLoader(true));
    try {
        const res = await post(`${GetLeadOverview}`, payload)
        dispatch(getBusinessLeadOverViewDashboardData(res.data[0]))
        dispatch(deActivateLoader(false))
    } catch (e) {
        dispatch(deActivateLoader(false))
    }
}

export const RecruitmentLeadOverviewDashboardData = (payload) => async (dispatch) => {
    dispatch(activateLoader(true));
    try {
        const res = await post(`${GET_RECRUITMENT_LEAD_OVERVIEW}`, payload)
        dispatch(getRecruitmentLeadsOverviewData(res.data))
        dispatch(deActivateLoader(false))
    } catch (e) {
        dispatch(deActivateLoader(false))
    }
}

export const RecruitmentLeadOverviewData = (payload) => async (dispatch) => {
    dispatch(activateLoader(true));
    try {
        const res = await post(`${GET_RECRUITMENT_LEAD_OVERVIEW}`, payload)
        console.log(res.data, 'responseleadoverview')
        dispatch(getRecruitmentLeadOverViewDashboardData(res.data[0]))
        dispatch(deActivateLoader(false))
    } catch (e) {
        dispatch(deActivateLoader(false))
    }
}

export const getAgencyLeadSourceData = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get('https://fulcrumone-dynamicform-service.dev.lms.fulcrumone.net/api/getsingledropdowndatabychannel/Leadsource?Channelid=3')
            dispatch(deActivateLoader())
            resolve(res?.data)

        } catch (err) {
            reject(err)
            dispatch(deActivateLoader())
        }
    })
}

export const getPehlaKadamCheckList = (checkListType) => async dispatch => {
    dispatch(activateLoader(true));
    try {
        await get(`${PEHLA_KADAM_CHECKLIST}/${checkListType}`)
            .then(response => {
                // console.log(response.data);
                dispatch(getPehlaKadamCheckListQus(response.data))
                dispatch(deActivateLoader())
            })

    } catch (e) {
        return console.error(e);

    }

}

export const getPehlaKadamGridList = (userid,checkListType) => async dispatch => {
    dispatch(activateLoader(true));
    try {
        await get(`${PEHLA_KADAM_QUS_GRID_LIST}/${userid}/${checkListType}`)
            .then(response => {
                // console.log(response.data);
                dispatch(getPehlaKadamGridQus(response.data))
                dispatch(deActivateLoader())
            })

    } catch (e) {
        return console.error(e);

    }

}

export const savePehlaKadamCheckList = (payload, history) => async dispatch => {
    dispatch(activateLoader(true));
    let formdata = {
        "jsondata": [JSON.stringify(payload)]
    }
    try {
        await post(`${PEHLA_KADAM_SAVE_CHECKLIST}`, formdata)
            .then(response => {
                dispatch(getCheckListSaveResponse(response))
                dispatch(deActivateLoader())
                // dispatch(activateSuccessMessageBar(`CheckList successfully Updated`))
                if (response.data.Responsestatus === '0000') {
                    dispatch(activateSuccessMessageBar("CheckList successfully Updated"))
                    history.push('/app/pehlaKadam/all');
                }
                if (response.data.Responsestatus === '1001') {
                    dispatch(activateErrorMessageBar("CheckList not successfully Updated"))
                }
                if (response.data.Responsestatus === '1002') {
                    dispatch(activateErrorMessageBar("CheckList already updated for BDM"))
                }

            }).catch((error) => {
                dispatch(activateErrorMessageBar(`CheckList not successfully Updated`))
                dispatch(deActivateLoader())
            })
    } catch (err) {
        dispatch(activateErrorMessageBar(`CheckList not successfully Updated`))
        return console.error(err);

    }
}

export const getAllBDMCheckList = (UserId,role, PageNo, NoOfRecordperPage, filterReq) => async dispatch => {
    dispatch(activateLoader(true));
    try {
        await
            post(`${PEHLA_KADAM_BDM_CHECKLIST}/${UserId}/${role}/${PageNo}/${NoOfRecordperPage}`, filterReq)
                .then(response => dispatch(setBDMChecklist(response.data)));
    } catch (e) {
        dispatch(activateErrorMessageBar(e.message))
        return console.error(e);
    } finally {
        dispatch(deActivateLoader(false))
    }
}

export const getPehlaKadamBDMData = (UserId,role, PageNo, NoOfRecordperPage, filterReq) => async dispatch => {
    dispatch(activateLoader(true));
    try {
        await
            post(`${PEHLA_KADAM_BDM_CHECKLIST}/${UserId}/${role}/${PageNo}/${NoOfRecordperPage}`, filterReq)
                .then(response => {
                    dispatch(setBDMData(response.data.Data[0]))
                }
                );
    } catch (e) {
        dispatch(activateErrorMessageBar(e.message))
        return console.error(e);
    } finally {
        dispatch(deActivateLoader(false))
    }
}

export const PehlaKadamDashboardData = (payload) => async (dispatch) => {
    dispatch(activateLoader(true));
    try {
        const res = await post(`${PEHLA_KADAM_DASHBORD_OVERVIEW}`, payload)
        dispatch(getPehlaKdamDashbordOverview(res.data[0]))
        dispatch(deActivateLoader(false))
    } catch (e) {
        dispatch(deActivateLoader(false))
    }
}

export const getPehlaKadamExportData = (UserId,role, PageNo, NoOfRecordperPage, filterReq) => async dispatch => {
    dispatch(activateLoader(true));
    try {
        await
            post(`${PEHLA_KADAM_EXPORT_DATA}/${UserId}/${role}/${PageNo}/${NoOfRecordperPage}`, filterReq)
                .then(response => {
                    console.log(response.data)
                    DownloadFiles(response.data)
                    dispatch(setExportDataFile(response.data))
                }
                );
    } catch (e) {
        dispatch(activateErrorMessageBar(e.message))
        return console.error(e);
    } finally {
        dispatch(deActivateLoader(false))
    }
}

export const getChecklistForBDMView = (UserId, checklistType) => async dispatch => {
    dispatch(activateLoader(true));
    try {
        await
            get(`${PEHLA_KADAM_BDM_VIEW}/${UserId}/${checklistType}`)
                .then(response => {
                    // console.log(response.data.GetBDMCheckListAnswer)
                    dispatch(setBDMView(response.data.GetBDMCheckListAnswer))
                    dispatch(setBDMViewProfile(response.data.BDMHistory))

                });
    } catch (e) {
        dispatch(activateErrorMessageBar(e.message))
        return console.error(e);
    } finally {
        dispatch(deActivateLoader(false))
    }
}

// export const getPehlaKadamExportDataForRBH = (UserId, PageNo, NoOfRecordperPage, filterReq) => async dispatch => {
//     dispatch(activateLoader(true));
//     try {
//         await
//             post(`${PEHLA_KADAM_EXPORT_DATA_FOR_RBH}/${UserId}/${PageNo}/${NoOfRecordperPage}`, filterReq)
//                 .then(response => {
//                     console.log(response.data)
//                     DownloadFiles(response.data)
//                     dispatch(setExportDataFile(response.data))
//                 }
//                 );
//     } catch (e) {
//         dispatch(activateErrorMessageBar(e.message))
//         return console.error(e);
//     } finally {
//         dispatch(deActivateLoader(false))
//     }
// }

// export const getAllRBHCheckList = (UserId, PageNo, NoOfRecordperPage, filterReq) => async dispatch => {
//     dispatch(activateLoader(true));
//     try {
//         await
//             post(`${PEHLA_KADAM_RBH_CHECKLIST}/${UserId}/${PageNo}/${NoOfRecordperPage}`, filterReq)
//                 .then(response => dispatch(setBDMChecklist(response.data)));
//     } catch (e) {
//         dispatch(activateErrorMessageBar(e.message))
//         return console.error(e);
//     } finally {
//         dispatch(deActivateLoader(false))
//     }
// }

export const getAgencyRecruitReasonData = (channelID) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        dispatch(activateLoader())
        try {
            const res = await get(`${GET_MASTER_DATA_BY_CHANNEL_ID}droppedreason?Channelid=${channelID}`)
            resolve(res)
            dispatch({
                type: 'AgencyRecruitReason',
                payload: res.data
            })
            dispatch(deActivateLoader())
        } catch (err) {
            reject(err)
        }
    })

}

export const getAbhExportData =
    (UserId, PageNo, NoOfRecordperPage, filterReq) => async (dispatch) => {
        dispatch(activateLoader(true));
        // try {
        //   await post(
        //     `${PEHLA_KADAM_EXPORT_DATA}/${UserId}/${PageNo}/${NoOfRecordperPage}`,
        //     filterReq
        //   ).then((response) => {
        //     console.log(response.data);
        //     DownloadFiles(response.data);
        //     dispatch(setExportDataFile(response.data));
        //   });
        // } catch (e) {
        //   dispatch(activateErrorMessageBar(e.message));

        //   return console.error(e);
        // } finally {
        //   dispatch(deActivateLoader(false));
        // }
    };

    export const getBusinessLeadSourceOverviewData = (payload) => async (dispatch) => {
        return new Promise(async (resolve, reject) => {
            dispatch(activateLoader())
            try {
                const res = await post(`${GET_BUSINESS_LEAD_SOURCE_OVERVIEW_DATA}`, payload)
                console.log(res.data, 'responseleadoverview')
                dispatch(setBusinessLeadSourceDashboardOverview(res.data[0]))
                dispatch(deActivateLoader())
                resolve(res)
            } catch (err) {
                dispatch(deActivateLoader())
                reject(err)
            }
        })
    }