const initialState = {
  Activities: [],
  activityTypeDetailCount: [],
  ExpenseType: [],
  ActivitySubType: [],
  ModeofTransport: [],
  Branches: [],
  VehicleType: [],
  Profession: [],
  Engagement: [],
  RescheduleReason: [],
  PersistencyRescheduleReason: [],
  CallBackReason: [],
  PersistencyCallBackReason: [],
  FollowupReason: [],
  droppedreason: [],
  PersistencyDroppedReason: [],
  IncomeGroupDetails: [],
  ProductDetails: [],
  Salutation: [],
  LeadType: [],
  SPType: [],
  LeadSource: [],
  ActivityStatus: [],
  messageBar: {
    severity: "success",
    message: "",
    isActive: false,
  },
  isActiveLoader: false,
  TrainingFor: [],
  ReviewWith: [],
  MeetingWith: [],
  breadcrumbs: [],
  State: [],
  District: [],
  MeetingType: [],
  ActivityDoneWith: [],
  RecruitmentFor: [],
  VisitTo: [],
  AgencyAdvisorMapping: [],
  isCloseNotification: false,
  readAllNotifications: false,
  PriorityStatus: [],
  viewForStatus: "",
  GclActivitytype: [],
  selectedBDMUser: 0,
  PaymentType: [],
  PaymentSubType: [],
  JointSalesCallWithSRMActivitySubType: [],
  FPMFPHMeetingType: [],
  ActivitiesForHNI: [],
  SRMList: [],
  SmaReasons: [],
  SmaActivityDuration: [],
  smaTrainingType: [],
  smaBdmBranch: [],
  smaBankRegion: [],
  ZMAList: [],
  PremierZMAList: [],
  AdvisorList: [],
  EAPList: [],
   CheckListType:[],
  CheckListStatus:[],
  AgencyRecruitReason: [],
  getBranchDetails: [],
  LoanType: [],
  GCLCallDuration: [],
  GetBDMByABH: 'all',
  BrocaActivityPlanned: [],
  BrocaTrainingTopic: [],
  BrocaJointCallActivity: [],
  BrocaJointCallActivityType: [],
  BrocaTopicsCovered: [],
  BrocaTrainingAttendee: [],
  BrocaDesignation: [],
  BrocaBFDActivity: [],
  BrocaLastAssignmentOf: [],
  BrocaMeetingPlannedWith: [],
  userBranchData: [],
  MSMELoanType: [],
  getPatnerDetails: [],
  Configuration_Setting:[],
  GoalType:[],
  getCollateral:false,
  getCollateralLogout:false,
  validatePersistencyLead:true,
  premiumCalculated:0,
  isCalculatorUsed:false,
  isDeviceReset:false,
  isUserFound:false,
  userIdForDeviceReset:null,
  isSettingUpActivate:true,
  isConsent:false,
  headerData:'',
};

function commonReducer(state = initialState, action) {
  switch (action.type) {
    case "Activities":
      return {
        ...state,
        Activities: action.payload,
      };
    case "ActivitiesForHNI":
      return {
        ...state,
        ActivitiesForHNI: action.payload,
      };
    case "SRMList":
      return {
        ...state,
        SRMList: action.payload
      }
    case "ZMAList":
      return {
        ...state,
        ZMAList: action.payload
      }
    case "PremierZMAList":
      return {
        ...state,
        PremierZMA: action.payload
      }
    case "AdvisorList":
      return {
        ...state,
        AdvisorList: action.payload
      }
    case "EAPList":
      return {
        ...state,
        EAPList: action.payload
      }
    case "ExpenseType":
      return {
        ...state,
        ExpenseType: action.payload,
      };
    case "ActivitySubType":
      return {
        ...state,
        ActivitySubType: action.payload,
      };
    case "FPMFPHMeetingType":
      return {
        ...state,
        FPMFPHMeetingType: action.payload,
      };
    case "JointSalesCallWithSRMActivitySubType":
      return {
        ...state,
        JointSalesCallWithSRMActivitySubType: action.payload,
      };
    case "ModeofTransport":
      return {
        ...state,
        ModeofTransport: action.payload,
      };
    case "Branches":
      return {
        ...state,
        Branches: action.payload,
      };
    case "VehicleType":
      return {
        ...state,
        VehicleType: action.payload,
      };
    case "ActivityStatus":
      return {
        ...state,
        ActivityStatus: action.payload,
      };
    case "ProductDetails":
      return {
        ...state,
        ProductDetails: action.payload,
      };
    case "Salutation":
      return {
        ...state,
        Salutation: action.payload,
      };
    case "LeadType":
      return {
        ...state,
        LeadType: action.payload,
      };
    case "SPType":
      return {
        ...state,
        SPType: action.payload,
      };
    case "LeadSource":
      return {
        ...state,
        LeadSource: action.payload,
      };
    case "CallBackReason":
      return {
        ...state,
        CallBackReason: action.payload,
      };
    case "PersistencyCallBackReason":
      return {
        ...state,
        PersistencyCallBackReason: action.payload,
      };
    case "RescheduleReason":
      return {
        ...state,
        RescheduleReason: action.payload,
      };
    case "PersistencyRescheduleReason":
      return {
        ...state,
        PersistencyRescheduleReason: action.payload,
      };
    case "FollowupReason":
      return {
        ...state,
        FollowupReason: action.payload,
      };
    case "droppedreason":
      return {
        ...state,
        droppedreason: action.payload,
      };
    case "PersistencyDroppedReason":
      return {
        ...state,
        PersistencyDroppedReason: action.payload,
      };
    case "ACTIVATE_SUCCESS_MESSAGE_BAR":
      return {
        ...state,
        messageBar: {
          severity: "success",
          message: action.payload,
          isActive: true,
        },
      };
    case "ACTIVATE_ERROR_MESSAGE_BAR":
      return {
        ...state,
        messageBar: {
          severity: "error",
          message: action.payload,
          isActive: true,
        },
      };
    case "DEACTIVATE_MESSAGE_BAR":
      return {
        ...state,
        messageBar: {
          severity: "success",
          message: "",
          isActive: false,
        },
      };
    case "CLOSE_NOTIFICATION":
      return {
        ...state,
        isCloseNotification: action.payload,
      };
    case "READ_ALL_NOTIFICATION":
      return {
        ...state,
        readAllNotifications: action.payload,
      };
    case "ACTIVATE_LOADER":
    case "DEACTIVATE_LOADER":
      return {
        ...state,
        isActiveLoader: action.payload,
      };
    case "SET_BREADCRUMBS":
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    case "TrainingFor":
      return {
        ...state,
        TrainingFor: action.payload,
      };
    case "ReviewWith":
      return {
        ...state,
        ReviewWith: action.payload,
      };
    case "MeetingWith":
      return {
        ...state,
        MeetingWith: action.payload,
      };
    case "State":
      return {
        ...state,
        State: action.payload,
      };
    case "District":
      return {
        ...state,
        District: action.payload,
      };
    case "MeetingType":
      return {
        ...state,
        MeetingType: action.payload,
      };
    case "ActivityDoneWith":
      return {
        ...state,
        ActivityDoneWith: action.payload,
      };
    case "RecruitmentFor":
      return {
        ...state,
        RecruitmentFor: action.payload,
      };
    case "VisitTo":
      return {
        ...state,
        VisitTo: action.payload,
      };
    case "AgencyAdvisorMapping":
      return {
        ...state,
        AgencyAdvisorMapping: action.payload,
      };
    case "Priority":
      return {
        ...state,
        PriorityStatus: action.payload,
      };
    case "viewFor":
      return {
        ...state,
        viewForStatus: action.payload,
      };
    case "GclActivitytype":
      return {
        ...state,
        GclActivitytype: action.payload,
      };

    case "BDMUser":
      return {
        ...state,
        selectedBDMUser: action.payload,
      };
    case "PaymentType":
      return {
        ...state,
        PaymentType: action.payload,
      };
    case "PaymentSubType":
      return {
        ...state,
        PaymentSubType: action.payload,
      };

    case "SmaReasons":
      return {
        ...state,

        SmaReasons: action.payload,
      };

    case "smaTrainingType":

      return {
        ...state,

        smaTrainingType: action.payload,

      };
    case "SmaActivityDuration":
      return {
        ...state,
        SmaActivityDuration: action.payload,
      };
    case "smaBdmBranch":
      return {
        ...state,
        smaBdmBranch: action.payload,
      };
    case "smaBankRegion":
      return {
        ...state,
        smaBankRegion: action.payload,
      };
    case "activityTypeDetailCount":
      return {
        ...state,
        activityTypeDetailCount: action.payload,
      };
    case "CheckListType":
      return {
        ...state,
        CheckListType:action.payload,
      };
      case "CheckListStatus":
      return {
        ...state,
        CheckListStatus:action.payload,
      };
    case "AgencyRecruitReason":
      return {
        ...state,
        AgencyRecruitReason: action.payload,
      };
    case "SetBDMByABH":
      return {
        ...state,
        GetBDMByABH: action.payload,
      };
    case "getBranchDetails":
      return {
        ...state,
        getBranchDetails: action.payload,
      };
    case "LoanType":
      return {
        ...state,
        LoanType: action.payload,
      };
    case "GCLCallDuration":
      return {
        ...state,
        GCLCallDuration: action.payload,
      };
    case "BrocaTopicsCovered":
      return {
        ...state,
        BrocaTopicsCovered: action.payload,
      };
    case "BrocaTrainingAttendee":
      return {
        ...state,
        BrocaTrainingAttendee: action.payload,
      };
    case "BrocaDesignation":
      return {
        ...state,
        BrocaDesignation: action.payload,
      };
    case "BrocaActivityPlanned":
      return {
        ...state,
        BrocaActivityPlanned: action.payload,
      };
    case "BrocaTrainingTopic":
      return {
        ...state,
        BrocaTrainingTopic: action.payload,
      };
    case "BrocaJointCallActivity":
      return {
        ...state,
        BrocaJointCallActivity: action.payload,
      };
    case "BrocaJointCallActivityType":
      return {
        ...state,
        BrocaJointCallActivityType: action.payload,
      };
    case "BrocaCampaigningDone":
      return {
        ...state,
        BrocaCampaigningDone: action.payload,
      }
    case "BrocaBFDActivity":
      return {
        ...state,
        BrocaBFDActivity: action.payload,
      };
    case "BrocaLastAssignmentOf":
      return {
        ...state,
        BrocaLastAssignmentOf: action.payload,
      };
    case "BrocaMeetingPlannedWith":
      return {
        ...state,
        BrocaMeetingPlannedWith: action.payload,
      };
    case "userBranchData":
      return {
        ...state,
        userBranchData: action.payload,
      };
    case "MSMELoanType":
      return {
        ...state,
        MSMELoanType: action.payload,
      };
    case "getPatnerDetails":
      return {
        ...state,
        getPatnerDetails: action.payload,
      };
    case "setCollateral":
      return {
        ...state,
        getCollateral: action.payload,
      };
    case "validatePersistencyLead":
      return {
        ...state,
        validatePersistencyLead: action.payload,
      };
    case "Configuration_Setting":
      return {
        ...state,
        Configuration_Setting: action.payload,
      };
    case "GoalType":
      return {
        ...state,
        GoalType: action.payload,
      };
    case "premiumCalculated":
      return {
        ...state,
        premiumCalculated: action.payload,
      };
    case "isCalculatorUsed":
      return {
        ...state,
        isCalculatorUsed: action.payload,
      };
      case "IntrestedLeadDob":
      return {
          ...state,
        intrestedLeadDob: action.payload,
      };
    case "isDeviceReset":
      return {
        ...state,
        isDeviceReset: action.payload,
      };
    case "userIdForDeviceReset":
      return {
        ...state,
        userIdForDeviceReset: action.payload,
      };
    case "isSettingUpActivate":
      return {
        ...state,
        isSettingUpActivate: action.payload,
      };
    case "isUserFound":
      return {
        ...state,
        isUserFound: action.payload,
      };
    case "isConsent":
      return {
        ...state,
        isConsent: action.payload,
      };
    case "headerData":
      return {
        ...state,
        headerData: action.payload,
      };
    // case "setCollateralLogout":
    //   return {
    //     ...state,
    //     getCollateralLogout: action.payload,
    //   };

    default:
      return state;
  }
}

export default commonReducer;
