import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import "./leadList.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getFilterLeadList,
  getLeadListByIdForTable,
  getLeadStatusList,
} from "../../../redux-reducer/reducer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import LeadSourceFilter from "./leadSouceFilter";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import {
  getAllLeadOffline,
  getFilterLeads,
} from "../../../offlineManager/leadOfflineManager";
import { getLeadList, setLeadsOverview, setRecruitmentLeadsOverview } from "../../../redux-reducer/reducer/leadListReducer";
import Listing from "../../../components/listing/listing";
import { useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


function BusinessLeadSource() {
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();
  const formRef = useRef();
  const location = useLocation();
  const params = useParams();
  const commonState = useSelector((reducerState) => reducerState);
  const { getLeadData, getLeadsOverView, getRecruitmentLeadsOverView } = commonState.leadListReducer
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const userId = JSON.parse(userData.userReducer).profileInfo.userId;
  const roleId = JSON.parse(userData.userReducer).profileInfo.roleId;
  const [selectedFilterLead, setSelectedFilterLead] = useState({
    roleId: roleId,
    viewFor: ""
  })


  const [state, setState] = React.useState({
    bottom: false,
    right: false
  });

  const [value, setValue] = React.useState(0);
  const [selectedTeamMemberLead, setSelectedTeamMemberLead] = useState("")


  const { Listofleads, TotalnoofRows } = getLeadData;
  const IsRecruitmentlead = location.pathname.includes("recruitmentlead")
  const [NoOfRecordperPage, setNoOfRecordperPage] = useState(5);
  const [allOfflineLeads, setAllOfflineLeads] = useState([]);
  const { viewForStatus, GetBDMByABH } = useSelector(reducer => reducer.commonReducer)

  const [leadFilterState, setLeadFilterState] = useState({
    UserName: "",
    CreatedDateTime: "null",
    CreatedDateStartTIme: "null",
    CreatedDateEndTIme: "null",
    MeetingOn: "null",
    LastUpdateDateTime: "null",
    currentStatus: 0,
    currentstatus: [],
    branchNameCode: 0,
    typeOfSP: 0,
    product: 0,
    leadSource: 1,
    LeadName: null,
    LeadTypeID: 0,
    activityID: 0,
    MeetingDate: "null",
    fromdate: "null",
    todate: "null",
    MobileNo: null,
    Modifiedstartdate: "null",
    Modifiedenddate: "null",
    ExpectedLeadClosureDate: "null",
    MobileNumber: null,
  });


  const [leadReq, setLeadReq] = useState({
    Noofdays: 0,
    LeadtypeID: null,
    Leadname: null,
    ActivityID: null,
    CreatedBy: userId.toString(),
    Createddate: null,
    LeadStatusId: "1,2,3,4,5,6,7,8,9,10,14,15,16,17,18,19,20,21,22,23,24",
    MeetingDate: null,
    Modifieddate: null,
    LeadSourceID: params.id,
    ProductID: null,
    SPTypeID: null,
    BranchID: null,
    MeetingStartdate: null,
    MeetingEnddate: null,
    IsBacklog: true,
    Modifiedstartdate: null,
    Modifiedenddate: null,
    CreatedDateStartTIme: null,
    CreatedDateEndTIme: null,
    IsSpotLeadClosure: 0,
    ExpectedLeadClosureDate: null,
  });




  const getLeadByOverviewSelection = (type) => {
    switch (type) {
      case "open":
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, LeadStatusId: "2,3,4,7,15,17", IsRecruitmentlead: IsRecruitmentlead ? 1 : 0, IsSpotLeadClosure: 0 }
        };
      case "un-actioned":
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, LeadStatusId: "1,18", IsRecruitmentlead: IsRecruitmentlead ? 1 : 0, IsSpotLeadClosur: 0 }
        };
      case "converted":
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, LeadStatusId: "6,19,20,24,22,21", IsRecruitmentlead: IsRecruitmentlead ? 1 : 0, IsSpotLeadClosure: 0 },
        };
      case 'dropped':
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, LeadStatusId: "5,16", IsRecruitmentlead: IsRecruitmentlead ? 1 : 0 },
        };
      case 'all':
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, IsRecruitmentlead: IsRecruitmentlead ? 1 : 0, IsSpotLeadClosure: 0 }
        };
        default:
          return {
            UserId: userId,
            PageNo: 1,
            NoOfRecordperPage: NoOfRecordperPage,
            filterReq: { ...leadReq, IsRecruitmentlead: IsRecruitmentlead ? 1 : 0, IsSpotLeadClosure: 0 }
          }
    }
  };

  useEffect(() => {
    if (isOnline) {
      const payload = getLeadByOverviewSelection(IsRecruitmentlead ? getRecruitmentLeadsOverView : getLeadsOverView)
      setLeadReq({ ...leadReq, LeadStatusId: payload.filterReq.LeadStatusId })
      if (value == 0) {
        dispatch(getLeadListByIdForTable(payload, userId, roleId, viewForStatus, GetBDMByABH));
      } else {
        dispatch(getLeadListByIdForTable(payload, userId, roleId, viewForStatus, GetBDMByABH));
      }
    } else {
      getAllLeadOffline().then((leads) => {
        leads = leads.sort(
          (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)
        );
        const initialLeadList = leads;
        const leadResponse = {
          Noofpages: Math.round(leads.length / NoOfRecordperPage),
          TotalnoofRows: leads.length,
          Listofleads: initialLeadList.slice(0, NoOfRecordperPage),
        };
        setAllOfflineLeads(leads);
        dispatch(getLeadList(leadResponse));
      });
    }

    return () => {
      // Anything in here is fired on component unmount.
      dispatch(setLeadsOverview("all"));
      dispatch(setRecruitmentLeadsOverview('all'));
    }
  }, [value]);

  useEffect(() => {
    if (isOnline) {
      dispatch(getLeadStatusList());
    }
    setSelectedFilterLead({
      roleId: roleId,
      viewFor: viewForStatus
    })


  }, []);


  const loadMoreRecord = () => {
    const payload = {
      UserId: userId,
      PageNo: 1,
      NoOfRecordperPage: NoOfRecordperPage + 5,
      filterReq: {
        ...leadReq,
      },
    };
    if (isOnline) {
      const { viewFor } = selectedFilterLead
      dispatch(getLeadListByIdForTable(payload, userId, roleId, viewFor, selectedTeamMemberLead ? selectedTeamMemberLead : GetBDMByABH));
      setNoOfRecordperPage(NoOfRecordperPage + 5);
    } else {
      let batchLeads = [...allOfflineLeads];
      batchLeads = batchLeads.splice(NoOfRecordperPage, NoOfRecordperPage + 5);
      const leadResponse = {
        Noofpages: batchLeads.length / NoOfRecordperPage,
        TotalnoofRows: allOfflineLeads.length,
        Listofleads: batchLeads,
      };
      setNoOfRecordperPage(NoOfRecordperPage + 5);
      dispatch(getLeadList(leadResponse));
    }
  };

  useEffect(() => {
  }, [leadReq])


  const getFilteredLeads = (filterRequest, teamMemberID) => {
    scrollToTop();
    const req = {
      Noofdays: 0,
      LeadtypeID:
        filterRequest.LeadTypeID === 0
          ? null
          : parseInt(filterRequest.LeadTypeID),
      Leadname: filterRequest?.LeadName === 0 ? null : filterRequest.LeadName,
      ActivityID:
        filterRequest.activityID === 0
          ? null
          : parseInt(filterRequest.activityID),
      CreatedBy: userId.toString(),
      LeadStatusId:
        filterRequest.currentStatus === 0 ? getLeadStatusId(IsRecruitmentlead ? getRecruitmentLeadsOverView : getLeadsOverView) : filterRequest.currentStatus,

      // Modifieddate:
      //   filterRequest.LastUpdateDateTime === "null"
      //     ? null
      //     : filterRequest.LastUpdateDateTime,
      LeadSourceID:
        filterRequest.leadSource === 0
          ? null
          : parseInt(filterRequest.leadSource),
      ProductID:
        filterRequest.product === 0 ? null : parseInt(filterRequest.product),
      SPTypeID:
        filterRequest.typeOfSP === 0 ? null : parseInt(filterRequest.typeOfSP),
      BranchID:null,
      IsBacklog: true,
      MeetingStartdate: null,
      MeetingEnddate:null,
      MobileNo: filterRequest.MobileNo === 0 ? null : filterRequest.MobileNo,
      Createstartdate: null,
      Createenddate: null,
      Modifiedstartdate:  null ,
      Modifiedenddate: null,
      IsRecruitmentlead: IsRecruitmentlead ? 1 : 0,
      IsSpotLeadClosure: filterRequest.IsSpotLeadClosure ? 1 : 0,
      ExpectedLeadClosureDate: filterRequest.ExpectedLeadClosureDate == "null" ? null : filterRequest.ExpectedLeadClosureDate,
    };
    setLeadReq({
      ...leadReq,
      Noofdays: 0,
      LeadtypeID: req.LeadtypeID,
      Leadname: req.Leadname,
      ActivityID: req.ActivityID,
      CreatedBy: userId.toString(),
      LeadStatusId: req.LeadStatusId,
      Modifieddate: req.Modifieddate,
      LeadSourceID: req.LeadSourceID,
      ProductID: req.ProductID,
      SPTypeID: req.SPTypeID,
      BranchID: req.BranchID,
      MeetingStartdate: null,
      MeetingEnddate: null,
      IsBacklog: true,
      Modifiedenddate: null,
      Modifiedstartdate: null,
      CreatedDateStartTIme: null,
      CreatedDateEndTIme: req.Createenddate,
      IsRecruitmentlead: req.IsRecruitmentlead,
      IsSpotLeadClosure: req.IsSpotLeadClosure,
      ExpectedLeadClosureDate: req.ExpectedLeadClosureDate,
    })
    if (isOnline) {
      dispatch(getFilterLeadList(userId, 1, NoOfRecordperPage, req, selectedFilterLead, teamMemberID ? 'BDM' : viewForStatus, teamMemberID ? teamMemberID : GetBDMByABH));
      setSelectedTeamMemberLead(teamMemberID ? teamMemberID : GetBDMByABH)// viewFor === 'BDM' ? teamMemberID : ""
    } else {
      // filter leads offline
      let filterLeads = getFilterLeads(allOfflineLeads, req);
      filterLeads = filterLeads.sort(
        (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)
      );
      const initialLeadList = filterLeads;
      const leadResponse = {
        Noofpages: Math.round(filterLeads.length / NoOfRecordperPage),
        TotalnoofRows: filterLeads.length,
        Listofleads: initialLeadList.slice(0, NoOfRecordperPage),
      };
      setAllOfflineLeads(filterLeads);
      dispatch(getLeadList(leadResponse));
    }
  };
  const getLeadStatusId = (type) => {
    switch (type) {
      case "open":
        return "2,3,4,7"
      case "un-actioned":
        return "1"
      case "converted":
        return "6"
      case 'dropped':
        return "5"
      case 'all':
        return "1,2,3,4,5,6,7,8,9,10,14,15,16,17,18,19,20,21,22,23,24"
    }
  };
  const getAllLeadsOfUser = () => {
    scrollToTop();
    const payload = {
      UserId: userId,
      PageNo: 1,
      NoOfRecordperPage: 5,
      filterReq: {
        Noofdays: 0,
        LeadtypeID: null,
        Leadname: null,
        ActivityID: null,
        CreatedBy: userId.toString(),
        Createddate: null,
        LeadStatusId: getLeadStatusId(IsRecruitmentlead ? getRecruitmentLeadsOverView : getLeadsOverView),
        MeetingDate: null,
        Modifieddate: null,
        LeadSourceID: params.id,
        ProductID: null,
        SPTypeID: null,
        BranchID: null,
        MeetingStartdate: null,
        MeetingEnddate: null,
        IsBacklog: true,
        CreatedDateStartTIme: null,
        CreatedDateEndTIme: null,
        IsRecruitmentlead: IsRecruitmentlead ? 1 : 0
      },
    };
    setLeadReq({ ...payload.filterReq })
    setNoOfRecordperPage(5)
    if (isOnline) {
      dispatch(getLeadListByIdForTable(payload, userId, roleId, viewForStatus, GetBDMByABH));
    }
  };

  const scrollToTop = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };



  const toggleReAssignLeadDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  }

  useEffect(()=>{
    if(params?.id){
      setLeadFilterState({
        ...leadFilterState,
        leadSource:params.id
      });
      setLeadReq({
        ...leadReq,
        LeadSourceID:params.id
      });
    }
  },[params])
  useEffect(()=>{
    if(params?.id){
      setLeadFilterState({
        ...leadFilterState,
        leadSource:params.id
      });
    }
  },[params])

  return (
    <>
      <div className="tabBox">
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="tabs example"
        >
          <Tab label="Leads" {...a11yProps(0)} />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <div ref={formRef}>
          <Grid container columns={{ xs: 4, md: 12 }}>
            <Listing
              data={Listofleads}
              toggleDrawer={toggleDrawer}
              loadMoreRecord={loadMoreRecord}
              NoOfRecordperPage={NoOfRecordperPage}
              TotalnoofRows={TotalnoofRows}
              roleId={roleId}
              toggleReAssignLeadDrawer={toggleReAssignLeadDrawer}
            />

            <Grid
              item
              xs={12}
              md={3}
              className="rightCol"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <LeadSourceFilter
                filterState={leadFilterState}
                setFilterState={setLeadFilterState}
                getFilteredLeads={getFilteredLeads}
                getAllLeads={getAllLeadsOfUser}
                parent={"sidebar"}
                setSelectedFilter={setSelectedFilterLead}
                handleClose={toggleDrawer(state.bottom, false)}
                IsRecruitmentlead={IsRecruitmentlead ? 1 : 0}
              />
            </Grid>
          </Grid>
        </div>
        <span>
          {["bottom"].map((anchor) => (
            <React.Fragment key={anchor}>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                <LeadSourceFilter
                  anchor={anchor}
                  filterState={leadFilterState}
                  setFilterState={setLeadFilterState}
                  getFilteredLeads={getFilteredLeads}
                  getAllLeads={getAllLeadsOfUser}
                  parent={"drawer"}
                  setSelectedFilter={setSelectedFilterLead}
                  handleClose={toggleDrawer(anchor, false)}
                  IsRecruitmentlead={IsRecruitmentlead ? 1 : 0}
                />
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </span>
      </TabPanel>

    </>
  );
}

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, {
  })(BusinessLeadSource)
);
