import "font-awesome/css/font-awesome.min.css";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PersonIcon from '@material-ui/icons/Person';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  getBusinessLeadSourceOverviewData } from "../../../redux-reducer/reducer";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { setLeadsOverview } from "../../../redux-reducer/reducer/leadListReducer";
import { useHistory } from "react-router-dom";
import BarChart from "../../../components/barchart/barChart";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { levelbyUser } from "../../../utils/config";

const BusinessLeadSourceCard = (props) => {
  // props
  const { classes, selectedView, roleId, selectedBDM, selectedSecondLevelSubordinate,
    selectedThirdLevelSubordinate, selectedFourthLevelSubordinate } = props;
  const commonState = useSelector((state) => state);
  const { viewForStatus ,LeadSource} = commonState.commonReducer
  
  const userId = commonState.userReducer.profileInfo.userId
  const channelid = commonState.userReducer.profileInfo.channelid
  const { businessLeadSourceDashboardOverview,campaignLeadCampaignNames } = commonState.leadListReducer;

  const { OpenLead, DroppedLead, ConvertedLead, AllLead, UnActionLead,
    DroppedLeadExpectedPremium, ConvertedLeadExpectedPremium, OpenLeadExpectedPremium, UnActionExpectedPremium } = businessLeadSourceDashboardOverview
  const { DateDetails } = businessLeadSourceDashboardOverview;
  const history = useHistory()
  const [selectedDays, setSelectedDays] = useState("1");
  const [selectedCampaignName, setSelectedCampaignName] = useState("all")
  const [toggleChart, setToggleChart] = useState(false);
  const [toggleCard, setToggleCard] = useState(false);
  const [leadSouceData, setLeadSouceData] = useState([]);

  const bdmList = commonState.leadFormReducer.BDMList.UsersDetails;
  const secondLevelSubordinateList = commonState.leadFormReducer?.secondLevelSubordinateList?.UsersDetails;
  const thirdLevelSubordinateList = commonState.leadFormReducer?.thirdLevelSubordinateList?.UsersDetails;
  const fourthLevelSubordinateList = commonState.leadFormReducer?.fourthLevelSubordinateList?.UsersDetails;
  const All = "all";
  const [userDetails, setUserDetails] = useState({
    "userId": userId,
    "leadSourceID": "1",
    "roleName": roleId,
    "subordinateUserIds": "all",
    "campaignName": "all",
     "viewFor": "ALL",
    "channelid": channelid
  })


  // dispatch and useSelector
  const dispatch = useDispatch()
 

  const getCampaignNames = (payload) => {
    // dispatch(getCampaignLeadCampaignNames(payload))

  }

  const getCampaignListData = (payload) => {
    dispatch(getBusinessLeadSourceOverviewData(payload))
  }
  const getCampaignLeadCardData = () => {
    getCampaignActivityGraphData(selectedDays, "all")
  }
  const getCampaignActivityGraphData = (Days, campaignName) => {
    let payload = {}
    if (roleId === "NH" || roleId === "RBH" || roleId === "ZBH") {
      if (selectedBDM === 0) {
        payload = {
          userId: userId,
          roleName: roleId,
          viewFor: selectedView,
          subordinateUserIds: selectedBDM === 0 ? "ALL" : selectedBDM,
          leadSourceID: Days,
          channelid:channelid
        };
      } else if (selectedFourthLevelSubordinate) {
        const selectedBDMDetails = fourthLevelSubordinateList?.filter((bdm) => bdm.UserID === selectedFourthLevelSubordinate);
        payload = {
          userId: selectedBDMDetails[0]?.UserID,
          roleName: selectedBDMDetails[0]?.Level,
          viewFor: levelbyUser(channelid, roleId, "Five"),
          subordinateUserIds: All,
          leadSourceID: Days,
          channelid:channelid
        };
      } else if (selectedThirdLevelSubordinate) {
        const selectedABHDetails = thirdLevelSubordinateList?.filter((abh) => abh.UserID === selectedThirdLevelSubordinate);
        payload = {
          userId: selectedABHDetails[0]?.UserID,
          roleName: selectedABHDetails[0]?.Level,
          viewFor: levelbyUser(channelid, roleId, "Four"),
          subordinateUserIds: All,
          leadSourceID: Days,
          channelid:channelid
        };
      } else if (selectedSecondLevelSubordinate && selectedSecondLevelSubordinate !== "ALL") {
        const selectedBDMDetails = secondLevelSubordinateList?.filter((bdm) => bdm.UserID === selectedSecondLevelSubordinate);

        payload = {
          userId: selectedBDMDetails[0]?.UserID,
          roleName: selectedBDMDetails[0]?.Level,
          viewFor: levelbyUser(channelid, roleId, "Three"),
          subordinateUserIds: All,
          leadSourceID: Days,
          channelid:channelid
        };

      } else if (selectedSecondLevelSubordinate === "ALL") {
        const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === selectedBDM);
        payload = {
          userId: selectedBDM,
          roleName: selectedBDMDetails[0].Level,
          viewFor: viewForStatus,
          subordinateUserIds: "ALL",
          leadSourceID: Days,
          channelid:channelid
        };

      } else {
        const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === selectedBDM);
        payload = {
          userId: selectedBDM,
          roleName: selectedBDMDetails[0].Level,
          viewFor: levelbyUser(channelid, roleId, "Second"),
          subordinateUserIds: All,
          leadSourceID: Days,
          channelid:channelid
        };
      }
    } else {
      if (selectedBDM === 0) {
        payload = {
          userId: userId,
          roleName: roleId,
          viewFor: selectedView === 'ALL' ? 'BDM' : selectedView,
          subordinateUserIds: selectedView,
          leadSourceID: Days,
          channelid:channelid
        };
      } else {
        payload = {
          userId: userId,
          roleName: roleId,
          viewFor: 'BDM',
          subordinateUserIds: selectedBDM?.toString(),
          leadSourceID: Days,
          channelid:channelid
        };
      }
    }
    // setSelectedDays('WEEKLY')
    // getCampaignNames(payload);
    let req = {}
    if (roleId === "NH" || roleId === "RBH" || roleId === "ZBH") {
      if (selectedBDM === 0) {
        req = {
          userId: userId,
          roleName: roleId,
          viewFor: selectedView,
          subordinateUserIds: selectedBDM === 0 ? "ALL" : selectedBDM,
          leadSourceID: Days,
          campaignName: campaignName,
          channelid:channelid
        };
      } else if (selectedFourthLevelSubordinate) {
        const selectedBDMDetails = fourthLevelSubordinateList?.filter((bdm) => bdm.UserID === selectedFourthLevelSubordinate);
        req = {
          userId: selectedBDMDetails[0]?.UserID,
          roleName: selectedBDMDetails[0]?.Level,
          viewFor: levelbyUser(channelid, roleId, "Five"),
          subordinateUserIds: All,
          leadSourceID: Days,
          campaignName: campaignName,
          channelid:channelid
        };
      } else if (selectedThirdLevelSubordinate) {
        const selectedABHDetails = thirdLevelSubordinateList?.filter((abh) => abh.UserID === selectedThirdLevelSubordinate);
        req = {
          userId: selectedABHDetails[0]?.UserID,
          roleName: selectedABHDetails[0]?.Level,
          viewFor: levelbyUser(channelid, roleId, "Four"),
          subordinateUserIds: All,
          leadSourceID: Days,
          campaignName: campaignName,
          channelid:channelid
        };
      } else if (selectedSecondLevelSubordinate && selectedSecondLevelSubordinate !== "ALL") {
        const selectedBDMDetails = secondLevelSubordinateList?.filter((bdm) => bdm.UserID === selectedSecondLevelSubordinate);

        req = {
          userId: selectedBDMDetails[0]?.UserID,
          roleName: selectedBDMDetails[0]?.Level,
          viewFor: levelbyUser(channelid, roleId, "Three"),
          subordinateUserIds: All,
          leadSourceID: Days,
          campaignName: campaignName,
          channelid:channelid
        };

      } else if (selectedSecondLevelSubordinate === "ALL") {
        const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === selectedBDM);
        req = {
          userId: selectedBDM,
          roleName: selectedBDMDetails[0].Level,
          viewFor: viewForStatus,
          subordinateUserIds: "ALL",
          leadSourceID: Days,
          campaignName: campaignName,
          channelid:channelid
        };

      } else {
        const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === selectedBDM);
        req = {
          userId: selectedBDM,
          roleName: selectedBDMDetails[0].Level,
          viewFor: levelbyUser(channelid, roleId, "Second"),
          subordinateUserIds: All,
          leadSourceID: Days,
          campaignName: campaignName,
          channelid:channelid
        };
      }
    } else {
      if (selectedBDM === 0) {
        req = {
          userId: userId,
          roleName: roleId,
          viewFor: selectedView === 'ALL' ? 'BDM' : selectedView,
          subordinateUserIds: selectedView,
          leadSourceID: Days,
          campaignName: campaignName,
          channelid:channelid
        };
      } else {
        req = {
          userId: userId,
          roleName: roleId,
          viewFor: 'BDM',
          subordinateUserIds: selectedBDM?.toString(),
          leadSourceID: Days,
          campaignName: campaignName,
          channelid:channelid
        };
      }
    }

    getCampaignListData(req)
  }
  useEffect(() => {
    // setToggleCard(false)
    if(toggleCard){
    getCampaignActivityGraphData(selectedDays, "all")
    }
  }, [selectedBDM, selectedView, selectedSecondLevelSubordinate, selectedThirdLevelSubordinate, selectedFourthLevelSubordinate])
  const onChangehandler = (event) => {
    setSelectedCampaignName("all")
    setSelectedDays(event.target.value)
    setUserDetails({
      ...userDetails,
      leadSourceID: event.target.value,
      campaignName: "all"
    })
    getCampaignActivityGraphData(event.target.value, "all")
 }


  const setLeadOverview = (type) => {
    dispatch(setLeadsOverview(type));
    if (roleId == "NH" || roleId == "ZBH" || roleId == "RBH") {
      return false
    } else {
      history.push(`/app/leadSource/all/${selectedDays}`)
    }
  };
  const onIconClick = () => {
    setToggleChart(!toggleChart)
  }
  const onCardIconClick = (value) => {
    if (!value) {
      handleRefresh();
    }
    setToggleCard(!toggleCard)
  }
  const getYaxisLeadData = (type) => {
    const arr = []
    DateDetails && DateDetails.forEach((element) => {
      arr.push(element.HourDetails?.reduce((a, c) => {
        return a + c[type]
      }, 0))
    })
    return arr
  }

  const getChartDataForToday = (type) => {
    const arr = []
    DateDetails && DateDetails?.length > 0 && DateDetails[0].HourDetails.forEach((ele) => arr.push(ele[type]))
    return arr
  }
  const getChartData = (type) => {
    const chartDatas = {}
    switch (type) {
      case 'TODAY':
        chartDatas.labels = getChartDataForToday('Hour')
        chartDatas.datasets = [
          {
            label: "OpenLead",
            backgroundColor: "rgb(27,68,125)",
            data: getChartDataForToday('OpenLead')
          },
          {
            label: "UnActionLead",
            backgroundColor: "rgb(0,147,222)",
            data: getChartDataForToday('UnActionLead')
          },
          {
            label: "ConvertedLead",
            backgroundColor: "rgb(221,117,40)",
            data: getChartDataForToday('ConvertedLead')
          },
          {
            label: "DroppedLead",
            backgroundColor: "rgb(191,56,62)",
            data: getChartDataForToday('DroppedLead')
          }
        ]
        break
      case "abc":
        break
      default:
        const labelsArr = DateDetails && DateDetails.map((item) => item.CreatedDate)
        chartDatas.labels = labelsArr
        chartDatas.datasets = [
          {
            label: "OpenLead",
            backgroundColor: "rgb(27,68,125)",
            data: getYaxisLeadData('OpenLead')
          },
          {
            label: "UnActionLead",
            backgroundColor: "rgb(0,147,222)",
            data: getYaxisLeadData('UnActionLead')
          },
          {
            label: "ConvertedLead",
            backgroundColor: "rgb(221,117,40)",
            data: getYaxisLeadData('ConvertedLead')
          },
          {
            label: "DroppedLead",
            backgroundColor: "rgb(191,56,62)",
            data: getYaxisLeadData('DroppedLead')
          }
        ]
    }
    return chartDatas
  }
  const handleRefresh = () => {
    getCampaignLeadCardData();
  }

  useEffect(()=>{
    const LeadSourceBanca = ["Select", "SELF", "LG", "SRM", "BM", "OTHER", "QRCODE", "QLIK","UBIBL","CONNECTFIRST"];

    
    // let bancaLeadSource = [];

    if (LeadSource && LeadSource.length > 0) {
       const bancaLeadSource = LeadSource.filter((option) => {
            if (LeadSourceBanca.includes(option.key)) {
                return option;
            }
        }); 
        setLeadSouceData(bancaLeadSource)

    }

  },[LeadSource])
  return (
    <>
        <Paper className={classes.paper2} sx={{ mb: 2 }}>
            <Grid className="campaignLeadOverviewSec" container>
            {/* <AutorenewIcon className="zoomIcon" titleAccess="Refresh" onClick={()=> handleRefresh()}/> */}
              <Grid item xs={12} md={12} >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      <span onClick={()=> onCardIconClick(toggleCard)}>Business Leads Source {toggleCard ? <RemoveIcon /> : <AddIcon />}</span>
                    </Typography>
                    <p className="viewAll" onClick={() => setLeadOverview("all")}>View All</p>
                  </Grid>
                </Grid>
                { toggleCard ?
                  <Grid container className="selectDiv">
                    <Grid item xs={2.1} alignItems={'center'} >
                        <Typography variant="h5">Lead Source : </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className="selectBx selectBxFull">
                      {/* <InputLabel id="demo-simple-select-label">Lead Source</InputLabel> */}
                        <Select label="Day3" value={selectedDays} onChange={onChangehandler}>
                          {leadSouceData.map((option)=>{
                            return (
                              <MenuItem value={option.value}>{option.label} </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  :
                  ''
                }
                { toggleCard ?
                  <>
                  <Grid container className="greyView greyViewSml">
                    <Grid item xs={6} sm={3} onClick={() => setLeadOverview("open")}>
                      <div className="greyViewInner">
                        <ArrowRightAltIcon className="arrowIcon" />
                        <PersonIcon className="navyBlueBG" />
                        <div className="clearfix"></div>
                        <div className="titleActivity">Open Leads</div>
                        <div className="numDiv">
                          <span>{OpenLead}</span> / {AllLead}
                        </div>
                        <div className="costDiv">
                          <CurrencyRupeeIcon fontSize="small" />{OpenLeadExpectedPremium || 0}
                          <span>Expected Revenue</span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={3} onClick={() => setLeadOverview("un-actioned")} >
                      <div className="greyViewInner">
                        <ArrowRightAltIcon className="arrowIcon" />
                        <PersonIcon className="blueBG" />
                        <div className="clearfix"></div>
                        <div className="titleActivity">Un- actioned Leads</div>
                        <div className="numDiv">
                          <span>{UnActionLead ? UnActionLead : 0}</span> / {AllLead}
                        </div>
                        <div className="costDiv">
                          <CurrencyRupeeIcon fontSize="small" />{UnActionExpectedPremium ? UnActionExpectedPremium : 0}
                          <span>Expected Revenue</span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={3} onClick={() => setLeadOverview("converted")}>
                      <div className="greyViewInner">
                        <ArrowRightAltIcon className="arrowIcon" />
                        <PersonIcon className="orangeBG" />
                        <div className="clearfix"></div>
                        <div className="titleActivity">Converted Leads</div>
                        <div className="numDiv">
                          <span>{ConvertedLead ? ConvertedLead : 0}</span> / {AllLead}
                        </div>
                        <div className="costDiv">
                          <CurrencyRupeeIcon />{ConvertedLeadExpectedPremium ? ConvertedLeadExpectedPremium : 0}
                          <span>WRP</span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={3} onClick={() => setLeadOverview("dropped")}>
                      <div className="greyViewInner">
                        <ArrowRightAltIcon className="arrowIcon" />
                        <PersonIcon className="redBG" />
                        <div className="clearfix"></div>
                        <div className="titleActivity">Dropped Leads</div>
                        <div className="numDiv">
                          <span>{DroppedLead ? DroppedLead : 0}</span> / {AllLead}
                        </div>
                        <div className="costDiv">
                          <CurrencyRupeeIcon fontSize="small" />{DroppedLeadExpectedPremium ? DroppedLeadExpectedPremium : 0}
                          <span>Lost Revenue</span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h5" gutterBottom>
                        <span onClick={onIconClick}>Analytical Overview {toggleChart ? <RemoveIcon /> : <AddIcon />}</span>
                      </Typography>
                      {toggleChart ? <BarChart chartData={getChartData(selectedDays)} /> : ""}

                    </Grid>
                  </Grid>
                  </>
                  : ''
                }
              </Grid>
            </Grid>
        </Paper>
    </>
  );
}

export default BusinessLeadSourceCard;
