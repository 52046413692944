import React, { Fragment, useState, useEffect } from "react";

import moment from "moment";
import './createLead.scss';
import './leadDetails.scss';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChart';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import VideocamIcon from '@mui/icons-material/Videocam';
import SmsIcon from '@mui/icons-material/Sms';
import MailIcon from '@mui/icons-material/Mail';
import NavigationIcon from '@mui/icons-material/Navigation';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Link from '@mui/material/Link';
import HistoryCard from "./historyCard";
import CheckinMarker from '../../../assets/img/checkInMarkerDetails.svg';
import { getDateDuration, getMobileOS, addMinuteToDate } from '../../../utils/utils';
import { Drawer } from "@mui/material";
import MapRoute from "./mapRoute";
import { useParams } from "react-router";
import { ALLOWED_CHECK_IN_LEAD } from "../../../utils/config";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { useSelector } from 'react-redux';
import ModalPopup from "f1-modal-react";
import { Button } from "@material-ui/core";
import { loggInfo } from "../../../redux/actions/userAction";
import { useLocation } from "react-router-dom";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const LeadLayout = (props) => {
    const { id } = useParams();
    const isOnline = useOnlineStatus();
    const locationData = useLocation();
    const isMobileOS = getMobileOS();
    const [showModalSetup, setShowModalSetup] = useState(false);
    const [drawerList, setDrwerList] = useState({
        'right': false
    })
    const [cords, setCoords] = useState({})
    const { SalutationID, SalutationName, FirstName, MiddleName, LastName, MobileNo, Email, Location, ProductID, ProductName,
        ExpectedPremium, LeadTypeID, LeadTypeName, ActivityID, BranchID, BranchName, MeetingStartDateTime,
        SPTypeID, SPTypeName, ISleadHasMeeting, LeadSourceName, LeadSourceID, SPID, LGID, AlternateNo, IsPremiumCollected,
        PriorityName, PriorityAnalyticStatusName, LeadId, LeadStatus, AssignedToName, CreatedDate, Salutation, ExpectedLeadClosureDate, CustomerType, CreatedByMsmeName,
        LGCode, SPCode, MeetingEndDateTime, Remarks, IsCheckin, IsClaimRequired, LeadStatusId, MSMESource, LeadCreationDate,
        CheckinCount, ActivityName, BranchCode, IsRecruitmentLead, Gender, DOB, GCLLeadSource, GCLCallDuration, LoanAmount, RateOfInterest, MSMELoanType, ActualPremiumAmount, LoanTerm, ActivitytypeID,
        PinCode, FinanicalBackroundID, EngagmentName, Profession, IncomeGroup, LoanType, ExpectedPremiumAmount, BankName, CampaigningDoneName, SMSName, LoanACReferenceNo, CoBorrower, InsuranceRequired, MemberFormFilled, IsSpotLeadClosure, BankCustomerID } = props.leadDetails; //ProfessionID,EngagementID,IncomeGroupID,

    const defaultFormVal = {
        SalutationID: SalutationID, Salutation: SalutationName, FirstName: FirstName, MiddleName: MiddleName, LastName: LastName, MobileNo: MobileNo, Email: Email,
        Location: Location, ProductID: ProductID, ProductName: ProductName, ExpectedPremium: ExpectedPremium,
        LeadTypeID: LeadTypeID, LeadTypeName: LeadTypeName, ActivityID: ActivityID,
        // ActivityIDlabel: `${ActivityName}-${ActivityBranchName}-${ActivityMeetingStartDateTime ? moment(ActivityMeetingStartDateTime).format('DD/MM/YYYY') : ''}`,
        // ActivityIDlabelAgency: `${ActivityName}-${ActivityBranchName?  `${ActivityBranchName} -` : ''}${ActivityMeetingStartDateTime ? moment(ActivityMeetingStartDateTime).format('DD/MM/YYYY') : ''}`,
        ActivityIDlabel: ActivityName,
        ActivityIDlabelAgency: ActivityName,
        SPTypeID: SPTypeID, SPTypeName: SPTypeName, ISleadHasMeeting: ISleadHasMeeting, LeadSourceID: LeadSourceID, ActivitytypeID: ActivitytypeID,
        LeadSourceName: LeadSourceName, BranchID: BranchID, BranchName: BranchName, SPID: SPID, LGID: LGID, CampaigningDoneName: CampaigningDoneName
    }
    const roleId = props.roleId;
    const commonState = useSelector(state => state)
    const channel = commonState.userReducer.profileInfo.channelid
    const userState = commonState.userReducer;
    const ZMA_LIST = commonState.commonReducer.ZMAList
    const advisorList = commonState.commonReducer.AdvisorList
    const PremierZMAList = commonState.commonReducer.PremierZMA
    const SRM_LIST = commonState.commonReducer.SRMList;


    //ZMA List
    const zmaArr = props.leadDetails.ZmaID?.split(",");
    const ZMA_LIST_NAME = ZMA_LIST?.filter(e => zmaArr?.includes(e.UserID?.toString())).map(el => {
        return `${el.Name} - ${el.Code}`

    })
    //Advoiser List
    const AdvisorIDArr = props.leadDetails.AdvisorID?.split(",")
    const Advisor_LIST_NAME = advisorList?.filter(e => AdvisorIDArr?.includes(e.UserID?.toString())).map(el => {
        return `${el.Name} - ${el.Code}`

    })
    //PremierZMAList
    const PremierZMAListArr = props.leadDetails.PremierZMAID?.split(",")
    const PremierZMAList_NAME = PremierZMAList?.filter(e => PremierZMAListArr?.includes(e.UserID?.toString())).map(el => {
        return `${el.Name} - ${el.Code}`

    })

    const SRMIDListArr = props.leadDetails?.SRMID; //36951; //
    const SRM_LIST_NAME = SRM_LIST?.filter(ele => parseInt(ele.value) == SRMIDListArr).map((el2) => {
        return el2.key + " (" + el2.label + ")"
    })

    useEffect(() => {
        //console.log("roleId",roleId);
        console.log("coords", cords);
    }, [])
    const getIntials = (name) => {
        if (name != null) {
            return name.slice(0, 1).toUpperCase();
        } else {
            return "";
        }
    }
    const leadlistlable = {
        'word-break': 'break-all',
        'margin-right': '17px',
    }
    const icons = {
        'font-size': ' 0.6rem',
        //'margin-left': '15%',
    }
    const iconalign = {
        'text-align': 'center',
    }
    const formatPhoneNumber = (str) => {
        const cleaned = `( ${str})`.replace(/\D/g, '');

        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `( ${match[1]} ) ${match[2]} ${match[3]}`.replace(/\d{4}$/, '****');
        }

        return null
    };
    const dateformat = "DD MMM YYYY";
    const AlternateNoForm = formatPhoneNumber(AlternateNo);


    const toggleDrawer = (anchor, open) => (event) => {
        console.log('toggleDrawer ', open);
        if (isOnline) {
            navigator.geolocation.getCurrentPosition((res) => {
                console.log(res);
                setCoords({ lat: res.coords.latitude, lng: res.coords.longitude })
                setShowModalSetup(false)
                if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                    return;
                }
                setDrwerList({ ...drawerList, [anchor]: open })
            }, (error) => {
                console.log('getCurrentPosition error leadLayout', error)
                setCoords({ lat: null, lng: null })
                setDrwerList(false)
                setShowModalSetup(true)
            })
        }
        if (open) {
            const employeeCode = userState.profileInfo.employeeCode;
            const deviceId = userState.profileInfo.deviceId;
            const logInfoRequest = {
                processname: 'Check-in drawer open',
                employeeCode: employeeCode,
                ipAddress: "",
                requestFor: `${'Check-In checkinlead'}`,
                leadActivityId: id,
                deviceId: deviceId,
                routUrl: locationData.pathname
            }
            loggInfo(logInfoRequest);
        }
    };
    const handleClose = () => {
        setShowModalSetup(false)
        toggleDrawer('right', true)
    }

    const handleAudioCall = (MobileNum) => {
        if (MobileNum) {
            window.open(`tel:${MobileNum}`)
        }
    }

    const handleSMS = (MobileNum) => {
        if (MobileNum) {
            window.open(`sms:${MobileNum}`)
        }
    }

    const handleVideoCall = () => {
        //video
    }

    const handleNavigate = () => {
        //naviagate

    }

    const handleEmail = (mail) => {
        if (mail) {
            window.open(`mailto:${mail}`)
        }

    }
    const PriorityColor = {
        Hot: '#DE4039',
        Warm: '#EF7A22',
        Cold: '#0093DE',
        High: '#DE4039',
        Medium: '#EF7A22',
        Low: '#0093DE'
    }
    const getUpdatedHistory = () => {
        props.onGetUpdatedHistory();
    }
    const bxSvg = "bxSvg activeClass";
    return < div >
        <Box sx={{ flexGrow: 1 }}>
            <Grid className="leadDetailsSec" container spacing={3}>
                <Grid item xs={12} md={4} >
                    <Paper className={props.classes.paper} sx={{ mb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid className="branchDiv" item xs={12} md={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={4} md={4} >
                                        <Paper className={props.classes.activityLogo} style={{ color: "blue" }}>
                                            <Typography variant="h5" component="h2">
                                                {getIntials(FirstName)} {getIntials(LastName)}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={8} md={8}>
                                        <Typography variant="h5" component="h2" className={props.classes.heading} style={leadlistlable}>
                                            <b> {Salutation}{" "}{FirstName}{" "}{LastName}</b>
                                        </Typography>
                                        {channel !== 79 &&
                                            <>
                                                {IsSpotLeadClosure == 1 ?
                                                    <> </> :
                                                    <>
                                                        <span className="icnNo"><BarChartIcon style={{ color: PriorityColor[PriorityName] }} /></span>
                                                        <span className='subTitle' style={{ color: PriorityColor[PriorityName] }}>
                                                            {PriorityName}{PriorityAnalyticStatusName ? `- ${PriorityAnalyticStatusName}` : " "} Lead</span>
                                                    </>
                                                }


                                            </>
                                        }
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} md={12} >
                                <List>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Lead ID</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {LeadId}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Current Status</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {IsRecruitmentLead === 1 ? `Recruitment ${LeadStatus}` : IsClaimRequired === 0 ? `Business ${LeadStatus}` : LeadStatus}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Assigned To</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {AssignedToName}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Assigned By</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >Self
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Created Date</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                <span className="date">{CreatedDate ? moment(CreatedDate).format(dateformat) : ""}</span>
                                                <span className="time">{CreatedDate ? moment(CreatedDate).format('h:mma') : ""}</span>
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    {MeetingStartDateTime &&
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Meeting Date </label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {IsSpotLeadClosure == 1 ?
                                                        <>N/A</> :
                                                        <>
                                                            <span className="date">
                                                                {MeetingStartDateTime ? moment(MeetingStartDateTime).format(dateformat) : ""}
                                                            </span>
                                                            <span className="time">
                                                                {MeetingStartDateTime ? moment(MeetingStartDateTime).format('h:mma') : ""}
                                                            </span>
                                                        </>
                                                    }

                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    }
                                    {ExpectedLeadClosureDate !== null && ExpectedLeadClosureDate !== undefined && userState.profileInfo.channelid == 1 &&
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Expected lead closure date</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    <span className="date">
                                                        {ExpectedLeadClosureDate ? moment(ExpectedLeadClosureDate).format(dateformat) : ""}
                                                    </span>

                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    }
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>

                    {!ALLOWED_CHECK_IN_LEAD.includes(LeadStatus) && (isMobileOS !== "Other") &&
                        <>
                            <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                                <Grid container spacing={1}>
                                    <List sx={{ width: '100%', bgcolor: 'background.paper' }} >
                                        <ListItem alignItems="flex-start" onClick={toggleDrawer('right', true)}>
                                            <Grid className='checkInDiv' container spacing={4} >
                                                <Grid item xs={4} md={4} >
                                                    <img src={CheckinMarker} alt={'Marker'}></img>
                                                </Grid>
                                                <Grid item xs={7} md={7} >
                                                    <label style={{ color: 'black' }}><b>Check In</b></label>
                                                </Grid>
                                                <Grid item xs={1} md={1} >
                                                    <span style={{ cursor: isOnline ? 'pointer' : 'not-allowed' }} onClick={toggleDrawer('right', true)} >
                                                        <ArrowRightAltIcon />
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Paper>
                            {['right'].map((anchor) => (
                                <Fragment key={anchor}>
                                    <Drawer
                                        anchor={anchor}
                                        open={drawerList[anchor]}
                                        onClose={toggleDrawer(anchor, false)}>

                                        <MapRoute status="checkinlead"
                                            checkinCount={CheckinCount}
                                            id={id} statusId={LeadStatusId}
                                            setDrwerList={setDrwerList}
                                            onCheckInComplete={getUpdatedHistory}
                                        />

                                    </Drawer>
                                </Fragment>
                            ))}
                        </>
                    }
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} >
                                        <Item className="activityDetailsHead"><b>Lead Personal Details </b></Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="leadPersonalDiv" item xs={12} md={12} >

                                <List>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Salutation</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {/* {IsSpotLeadClosure == 1 ? */}
                                                {/* <>N/A</> :
                                                    <> */}
                                                {Salutation ? Salutation : "-"}
                                                {/* </>
                                                } */}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>First Name</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                <span style={leadlistlable} >{FirstName}</span>
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                    {userState.profileInfo.channelid != 13 &&
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Middle Name</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    <span style={leadlistlable} >{MiddleName ? `${MiddleName} ` : "-"}</span>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    }
                                    {userState.profileInfo.channelid != 13 &&
                                        <Divider />}
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Last Name</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7}>
                                                <span style={leadlistlable} > {LastName}</span>
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                    {IsRecruitmentLead === 1 || userState.profileInfo.channelid == 13 && <>
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Gender</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7}>
                                                    <span style={leadlistlable} >{Gender}</span>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Date of Birth</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7}>
                                                    <span style={leadlistlable} > {moment(DOB).format("DD MMM YYYY")}</span>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </>}
                                    <Divider />
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Mobile No.</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {'+91 '}{MobileNo?.replace(/\d{4}$/, '****')}
                                                <Grid container>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className="bxSvg" onClick={() => handleAudioCall(MobileNo)}>
                                                                <WifiCalling3Icon />
                                                                <em>Audio Call</em>
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className="bxSvg" onClick={handleVideoCall}>
                                                                <VideocamIcon />
                                                                <em>Video Call</em>
                                                            </span>

                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className="bxSvg" onClick={() => handleSMS(MobileNo)}>
                                                                <SmsIcon />
                                                                <em>SMS</em>
                                                            </span>

                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                    {(userState.profileInfo.channelid !== 13 || userState.profileInfo.channelid !== 21) &&
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Alternate Mobile No.</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {IsSpotLeadClosure == 1 ?
                                                        <>N/A</> :
                                                        <>
                                                            {AlternateNoForm ? `+1 ${AlternateNoForm}` : ''}
                                                            <Grid container>
                                                                <Grid item xs={4} md={4}>
                                                                    <Grid item xs={12} md={12} style={iconalign}>
                                                                        <span className={AlternateNoForm ? 'bxSvg' : bxSvg} onClick={() => handleAudioCall(AlternateNoForm)}>
                                                                            <WifiCalling3Icon />
                                                                            <em>Audio Call</em>
                                                                        </span>

                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={4} md={4}>
                                                                    <Grid item xs={12} md={12} style={iconalign}>
                                                                        <span className={AlternateNoForm ? 'bxSvg' : bxSvg} onClick={() => handleVideoCall}>
                                                                            <VideocamIcon />
                                                                            <em>Video Call</em>
                                                                        </span>

                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={4} md={4}>
                                                                    <Grid item xs={12} md={12} style={iconalign}>
                                                                        <span className={AlternateNoForm ? 'bxSvg' : bxSvg} onClick={() => handleSMS(AlternateNoForm)}>
                                                                            <SmsIcon />
                                                                            <em>SMS</em>
                                                                        </span>

                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }

                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    }
                                    <Divider />
                                    {Email != null &&
                                        <><ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Email</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    <span style={leadlistlable}>{Email}</span>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={4} md={5} className="buttonBox" style={iconalign}>
                                                            <span className="bxSvg" onClick={() => handleEmail(Email)}>
                                                                <MailIcon />
                                                                <em>Email</em>
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                            <Divider /></>
                                    }

                                    {IsRecruitmentLead === 1 &&
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Pincode</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    <span style={leadlistlable}>{PinCode}</span>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>}
                                    <Divider />
                                    {userState.profileInfo.channelid !== 3 && Location &&
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Lead Location</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >


                                                    {IsSpotLeadClosure == 1 ?
                                                        <>N/A</> :
                                                        <>
                                                            {Location}
                                                            <Grid container spacing={4}>
                                                                <Grid item xs={4} md={5} className="buttonBox" style={iconalign}>
                                                                    <span className="bxSvg" onClick={handleNavigate}>
                                                                        <NavigationIcon />
                                                                        <em style={icons}>Navigate</em>
                                                                    </span>

                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }


                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    }
                                    {userState.profileInfo.channelid !== 3 &&
                                        <Divider />}
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                    {IsRecruitmentLead !== 1 && userState.profileInfo.channelid != 13 && userState.profileInfo.channelid != 79 && userState.profileInfo.channelid != 21 && ActivitytypeID != 69 &&
                        <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={12} >
                                            <Item className="activityDetailsHead"><b>
                                                Product Details</b></Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid className="listPT0 prodList" item xs={12} md={12} >
                                    <List>
                                        <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Product Name</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {IsSpotLeadClosure == 1 ?
                                                        <>N/A</> :
                                                        <>
                                                            {ProductName ? ProductName : "-"}
                                                        </>
                                                    }

                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Expected Premium</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >

                                                    {IsSpotLeadClosure == 1 ?
                                                        <>N/A</> :
                                                        <> {ExpectedPremium ? `₹ ${parseInt(ExpectedPremium)?.toLocaleString()}` : "-"}</>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </List>
                                </Grid>
                            </Grid>
                        </Paper>}
                    {userState.profileInfo.channelid == 13 &&
                        <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={12} >
                                            <Item className="activityDetailsHead"><b>
                                                Loan Details</b></Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid className="listPT0 prodList" item xs={12} md={12} >
                                    <List>
                                        {
                                            LoanACReferenceNo && <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Loan a/c Reference no</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {LoanACReferenceNo}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                        <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Loan Type</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {LoanType}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Loan amount</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {LoanAmount}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Expected Premium</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {parseInt(ExpectedPremiumAmount) ? `₹ ${parseInt(ExpectedPremiumAmount)}` : "-"}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Rate of Interest</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {RateOfInterest ? `${RateOfInterest} % ` : "N/A"}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        {
                                            CoBorrower && <><ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Co-Borrower</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {CoBorrower}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                                <Divider /></>
                                        }
                                        {
                                            channel === 13 && <><ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Insurance Required</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {InsuranceRequired ? "Yes" : "No"}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                                <Divider /></>
                                        }
                                        {
                                            channel === 13 && <><ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Member form filled and pending for collection</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {MemberFormFilled ? "Yes" : "No"}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                                <Divider /></>
                                        }
                                    </List>
                                </Grid>
                            </Grid>
                        </Paper>}

                    {userState.profileInfo.channelid == 79 &&
                        <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={12} >
                                            <Item className="activityDetailsHead"><b>
                                                Loan Details</b></Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid className="listPT0 prodList" item xs={12} md={12} >
                                    <List>
                                        <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Loan Type</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {MSMELoanType ? `${MSMELoanType} ` : "N/A"}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Loan amount</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {LoanAmount ? `${LoanAmount} ` : "N/A"}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Premium amount</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {ActualPremiumAmount ? `₹ ${parseInt(ActualPremiumAmount)?.toLocaleString()}` : "N/A"}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label> Premium Collection</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {IsPremiumCollected ? `${IsPremiumCollected == true ? "Yes" : "No"}` : "N/A"}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Loan Term</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {LoanTerm ? `${LoanTerm} ` : "N/A"}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </List>
                                </Grid>
                            </Grid>
                        </Paper>}
                </Grid>

                <Grid item xs={12} md={4}>
                    {IsRecruitmentLead !== 1 && <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} >
                                        <Item className="activityDetailsHead"><b>Business Details</b></Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {userState.profileInfo.channelid !== 21 ?
                                <Grid className="listPT0" item xs={12} md={12} >
                                    <Grid item xs={12} md={12} >
                                        <List>
                                            {userState.profileInfo.channelid !== 79 &&
                                                <>
                                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                        <Grid container spacing={4}>
                                                            <Grid item xs={5} md={5} >
                                                                <label>Lead Status</label>
                                                            </Grid>
                                                            <Grid className="lastitem" item xs={7} md={7} >
                                                                {IsSpotLeadClosure == 1 ?
                                                                    <>N/A</> :
                                                                    <>
                                                                        {PriorityName} Lead
                                                                    </>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </ListItemText>
                                                    <Divider />
                                                </>
                                            }
                                            {userState.profileInfo.channelid != 13 && userState.profileInfo.channelid != 79 &&
                                                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={5} md={5} >
                                                            <label>Lead Source</label>
                                                        </Grid>
                                                        <Grid className="lastitem" item xs={7} md={7} >
                                                            {IsSpotLeadClosure == 1 ?
                                                                <>N/A</> :
                                                                <>
                                                                    {LeadSourceName ? LeadSourceName : "N/A"}
                                                                </>
                                                            }

                                                        </Grid>
                                                    </Grid>
                                                </ListItemText>}
                                            {userState.profileInfo.channelid == 13 && userState.profileInfo.channelid != 79 &&
                                                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={5} md={5} >
                                                            <label>Lead Source</label>
                                                        </Grid>
                                                        <Grid className="lastitem" item xs={7} md={7} >
                                                            {GCLLeadSource ? GCLLeadSource : "N/A"}
                                                        </Grid>
                                                    </Grid>
                                                </ListItemText>}
                                            {userState.profileInfo.channelid === 79 &&
                                                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={5} md={5} >
                                                            <label>Lead Source</label>
                                                        </Grid>
                                                        <Grid className="lastitem" item xs={7} md={7} >
                                                            {MSMESource ? MSMESource : "N/A"}
                                                        </Grid>
                                                    </Grid>
                                                </ListItemText>}

                                            <Divider />
                                            {userState.profileInfo.channelid === 3 &&
                                                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={5} md={5} >
                                                            <label>Activity ID</label>
                                                        </Grid>
                                                        <Grid className="lastitem" item xs={7} md={7} >
                                                            <Link href="#"> {defaultFormVal.ActivityIDlabelAgency}</Link>
                                                        </Grid>
                                                    </Grid>
                                                </ListItemText>
                                            }
                                            {userState.profileInfo.channelid === 3 &&
                                                <Divider />
                                            }
                                            {userState.profileInfo.channelid !== 3 && userState.profileInfo.channelid !== 13 &&
                                                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={5} md={5} >
                                                            <label>Activity ID</label>
                                                        </Grid>
                                                        <Grid className="lastitem" item xs={7} md={7} >
                                                            {/* <Link href="#"> */}
                                                            {IsSpotLeadClosure == 1 ?
                                                                <>Spot Lead Closure</> :
                                                                <><Link href="#">
                                                                    {defaultFormVal.ActivityIDlabel}
                                                                </Link>
                                                                </>
                                                            }

                                                            {/* </Link> */}
                                                        </Grid>
                                                    </Grid>
                                                </ListItemText>
                                            }
                                            {userState.profileInfo.channelid !== 3 && userState.profileInfo.channelid !== 13 &&
                                                <Divider />
                                            }
                                            {userState.profileInfo.channelid !== 3 && userState.profileInfo.channelid !== 13 && userState.profileInfo.channelid !== 79 &&

                                                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={5} md={5} >
                                                            <label>Type of SP</label>
                                                        </Grid>
                                                        <Grid className="lastitem" item xs={7} md={7} >
                                                            {IsSpotLeadClosure == 1 ?
                                                                <>N/A</> :
                                                                <>
                                                                    {SPTypeName ? SPTypeName : "NA"}
                                                                </>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </ListItemText>
                                            }
                                            {userState.profileInfo.channelid !== 3 && userState.profileInfo.channelid !== 13 && userState.profileInfo.channelid !== 79 &&
                                                <Divider />
                                            }
                                            {userState.profileInfo.channelid !== 3 &&
                                                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={5} md={5} >
                                                            <label>Branch Name & Code</label>
                                                        </Grid>
                                                        <Grid className="lastitem" item xs={7} md={7} >
                                                            {BranchName ? BranchName : "NA"}{BranchCode ? `-${BranchCode}` : ""}
                                                        </Grid>
                                                    </Grid>
                                                </ListItemText>
                                            }
                                            <Divider />
                                            {userState.profileInfo.channelid == 13 &&
                                                <>
                                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                        <Grid container spacing={4}>
                                                            <Grid item xs={5} md={5} >
                                                                <label>Bank name</label>
                                                            </Grid>
                                                            <Grid className="lastitem" item xs={7} md={7} >
                                                                {BankName ? BankName : "NA"}
                                                            </Grid>
                                                        </Grid>
                                                    </ListItemText>
                                                    <Divider />
                                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                        <Grid container spacing={4}>
                                                            <Grid item xs={5} md={5} >
                                                                <label>SMS Code</label>
                                                            </Grid>
                                                            <Grid className="lastitem" item xs={7} md={7} >
                                                                {SMSName ? SMSName : "NA"}
                                                            </Grid>
                                                        </Grid>
                                                    </ListItemText>
                                                    <Divider />
                                                </>
                                            }
                                            {userState.profileInfo.channelid == 79 &&
                                                <>
                                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                        <Grid container spacing={4}>
                                                            <Grid item xs={5} md={5} >
                                                                <label>Customer Type</label>
                                                            </Grid>
                                                            <Grid className="lastitem" item xs={7} md={7} >
                                                                {CustomerType ? CustomerType : "N/A"}
                                                            </Grid>
                                                        </Grid>
                                                    </ListItemText>
                                                    <Divider />
                                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                        <Grid container spacing={4}>
                                                            <Grid item xs={5} md={5} >
                                                                <label>Created By</label>
                                                            </Grid>
                                                            <Grid className="lastitem" item xs={7} md={7} >
                                                                {CreatedByMsmeName ? CreatedByMsmeName : "NA"}
                                                            </Grid>
                                                        </Grid>
                                                    </ListItemText>
                                                    <Divider />
                                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                        <Grid container spacing={4}>
                                                            <Grid item xs={5} md={5} >
                                                                <label>Lead creation date</label>
                                                            </Grid>
                                                            <Grid className="lastitem" item xs={7} md={7} >
                                                                {LeadCreationDate ? moment(LeadCreationDate).format(dateformat) : ""}
                                                                {/* {LeadCreationDate ? LeadCreationDate :"NA"} */}
                                                            </Grid>
                                                        </Grid>
                                                    </ListItemText>
                                                    <Divider />
                                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                        <Grid container spacing={4}>
                                                            <Grid item xs={5} md={5} >
                                                                <label> Expected lead closure date </label>
                                                            </Grid>
                                                            <Grid className="lastitem" item xs={7} md={7} >
                                                                {ExpectedLeadClosureDate ? moment(ExpectedLeadClosureDate).format(dateformat) : ""}
                                                            </Grid>
                                                        </Grid>
                                                    </ListItemText>
                                                    <Divider />
                                                </>
                                            }
                                            {roleId === 'FPM' &&
                                                <>
                                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                        <Grid container spacing={4}>
                                                            <Grid item xs={5} md={5} >
                                                                <label>SRM code</label>
                                                            </Grid>
                                                            <Grid className="lastitem" item xs={7} md={7} >

                                                                {IsSpotLeadClosure == 1 ?
                                                                    <>N/A</> :
                                                                    <>
                                                                        {SRM_LIST_NAME}
                                                                    </>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </ListItemText>
                                                    <Divider />
                                                </>
                                            }
                                            {roleId !== 'FPM' &&
                                                <>
                                                    {userState.profileInfo.channelid !== 3 && userState.profileInfo.channelid !== 79 &&
                                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                            <Grid container spacing={4}>
                                                                <Grid item xs={5} md={5} >
                                                                    <label>LG Code</label>
                                                                </Grid>
                                                                <Grid className="lastitem" item xs={7} md={7} >
                                                                    {IsSpotLeadClosure == 1 ?
                                                                        <>N/A</> :
                                                                        <>
                                                                            {LGCode ? LGCode : "NA"}
                                                                        </>
                                                                    }

                                                                </Grid>
                                                            </Grid>
                                                        </ListItemText>
                                                    }
                                                    {userState.profileInfo.channelid !== 3 && userState.profileInfo.channelid !== 79 &&
                                                        <Divider />
                                                    }

                                                    {userState.profileInfo.channelid !== 3 && userState.profileInfo.channelid !== 13 && userState.profileInfo.channelid !== 79 &&
                                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                            <Grid container spacing={4}>
                                                                <Grid item xs={5} md={5} >
                                                                    <label>SP Code</label>
                                                                </Grid>
                                                                <Grid className="lastitem" item xs={7} md={7} >

                                                                    {IsSpotLeadClosure == 1 ?
                                                                        <>N/A</> :
                                                                        <>
                                                                            {SPCode ? SPCode : "NA"}
                                                                        </>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </ListItemText>
                                                    }
                                                    {userState.profileInfo.channelid !== 3 && userState.profileInfo.channelid !== 13 && userState.profileInfo.channelid !== 79 &&
                                                        <Divider />
                                                    }
                                                </>
                                            }
                                            {userState.profileInfo.channelid === 3 &&
                                                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={5} md={5} >
                                                            <label>ZMA /Premier ZMA Name</label>
                                                        </Grid>
                                                        <Grid className="lastitem" item xs={7} md={7} >
                                                            {(ZMA_LIST_NAME?.length > 0 ? ZMA_LIST_NAME.toString() : PremierZMAList_NAME?.length > 0 ? PremierZMAList_NAME.toString() : "NA")}
                                                        </Grid>
                                                    </Grid>
                                                </ListItemText>
                                            }
                                            {userState.profileInfo.channelid === 3 &&
                                                <Divider />
                                            }
                                            {userState.profileInfo.channelid === 3 &&
                                                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={5} md={5} >
                                                            <label>Advisor Name</label>
                                                        </Grid>
                                                        <Grid className="lastitem" item xs={7} md={7} >
                                                            {Advisor_LIST_NAME?.length > 0 ? Advisor_LIST_NAME.toString() : "NA"}
                                                        </Grid>
                                                    </Grid>
                                                </ListItemText>
                                            }
                                            {userState.profileInfo.channelid === 3 &&
                                                <Divider />
                                            }
                                            {userState.profileInfo.channelid !== 13 && userState.profileInfo.channelid !== 79 && <>
                                                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={5} md={5} >
                                                            <label>Meeting with Lead?</label>
                                                        </Grid>
                                                        <Grid className="lastitem" item xs={7} md={7} >
                                                            {IsSpotLeadClosure == 1 ?
                                                                <>N/A</> :
                                                                <>
                                                                    {ISleadHasMeeting ? 'Yes' : 'No'}
                                                                </>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </ListItemText>
                                                <Divider /> </>
                                            }
                                            {userState.profileInfo.channelid == 13 && <>
                                                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={5} md={5} >
                                                            <label>Meeting Duration</label>
                                                        </Grid>
                                                        <Grid className="lastitem" item xs={7} md={7} >
                                                            {GCLCallDuration ? GCLCallDuration : 'N/A'}
                                                        </Grid>
                                                    </Grid>
                                                </ListItemText>
                                                <Divider /> </>
                                            }
                                            {userState.profileInfo.channelid != 13 && userState.profileInfo.channelid !== 79 && <>
                                                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={5} md={5} >
                                                            <label>Meeting Date & Time</label>
                                                        </Grid>
                                                        <Grid className="lastitem" item xs={7} md={7} >

                                                            {IsSpotLeadClosure == 1 ?
                                                                <>N/A</> :
                                                                <>
                                                                    <span className="date">
                                                                        {MeetingStartDateTime ? moment(MeetingStartDateTime).format(dateformat) : ""}
                                                                    </span>
                                                                    <label className='time'>
                                                                        {MeetingStartDateTime ? moment(MeetingStartDateTime).format('h:mma') : ""}
                                                                        {/* -
                                                                        {MeetingStartDateTime ? moment(addMinuteToDate(MeetingEndDateTime)).format('h:mma') : ""} */}
                                                                    </label><br></br>
                                                                    {(ExpectedLeadClosureDate !== null && userState.profileInfo.channelid == 1) ? <> </> :
                                                                        <label className='duration'>
                                                                            {MeetingStartDateTime ?
                                                                                getDateDuration(MeetingStartDateTime, addMinuteToDate(MeetingEndDateTime), 'HOUR') : ''}
                                                                            {' '}
                                                                            {MeetingStartDateTime ?
                                                                                getDateDuration(MeetingStartDateTime, addMinuteToDate(MeetingEndDateTime), 'MINUTE')
                                                                                : ''}
                                                                        </label>
                                                                    }
                                                                </>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </ListItemText>
                                                <Divider />
                                                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={5} md={5} >
                                                            <label>Remarks</label>
                                                        </Grid>
                                                        <Grid className="lastitem" item xs={7} md={7} >
                                                            {IsSpotLeadClosure == 1 ?
                                                                <>N/A</> :
                                                                <>
                                                                    {Remarks ? Remarks : '-'}
                                                                </>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </ListItemText>
                                                <Divider />
                                                {userState.profileInfo.channelid == 1 && <>
                                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                        <Grid container spacing={4}>
                                                            <Grid item xs={5} md={5} >
                                                                <label>Bank Customer ID</label>
                                                            </Grid>
                                                            <Grid className="lastitem" item xs={7} md={7} >
                                                                {IsSpotLeadClosure == 1 ?
                                                                    <>{roleId === 'FPM' ? BankCustomerID == null ? 'N/A' : BankCustomerID : 'N/A'}</> :
                                                                    <>
                                                                        {BankCustomerID ? BankCustomerID == null ? 'N/A' : BankCustomerID : 'N/A'}
                                                                    </>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </ListItemText>
                                                </>
                                                }
                                            </>
                                            }

                                        </List>
                                    </Grid>
                                </Grid>
                                :
                                <Grid className="listPT0" item xs={12} md={12} >
                                    <Grid item xs={12} md={12} >
                                        <List>
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Lead Status</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {PriorityName} Lead
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                            <Divider />
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Activity ID</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        <Link href="#"> {defaultFormVal.ActivityIDlabelAgency}</Link>
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                            <Divider />
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Activity Type</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {CampaigningDoneName}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                            <Divider />
                                        </List>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Paper>}
                    <>
                        {IsRecruitmentLead === 1 && <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={12} >
                                            <Item className="activityDetailsHead"><b>Others </b></Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid className="leadPersonalDiv" item xs={12} md={12} >

                                    <List>
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Type of Lead</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {LeadTypeName}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Activity ID</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    <Link href="#"> {defaultFormVal.ActivityIDlabelAgency}</Link>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Profession</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7}>
                                                    <span style={leadlistlable} > {Profession}</span>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Engagment Name</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7}>
                                                    <span style={leadlistlable} > {EngagmentName}</span>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Income Group</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7}>
                                                    <span style={leadlistlable} > {IncomeGroup}</span>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Financial Background</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7}>
                                                    <span style={leadlistlable} > {FinanicalBackroundID === 1 ? "Yes" : "No"}  </span>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        {userState.profileInfo.channelid !== 3 && Location &&
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Lead Location</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >

                                                        {Location}
                                                        <Grid container spacing={4}>
                                                            <Grid item xs={4} md={5} className="buttonBox" style={iconalign}>
                                                                <span className="bxSvg" onClick={handleNavigate}>
                                                                    <NavigationIcon />
                                                                    <em style={icons}>Navigate</em>
                                                                </span>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                        {userState.profileInfo.channelid !== 3 &&
                                            <Divider />}
                                    </List>
                                </Grid>
                            </Grid>
                        </Paper>}
                    </>
                    <HistoryCard classes={props.classes} leadHistoryData={props.hitory}
                        Item={Item} ActivityIDlabel={defaultFormVal.ActivityIDlabel} ActivityIDlabelAgency={defaultFormVal.ActivityIDlabelAgency}
                        IsCheckin={IsCheckin} IsClaimRequired={IsClaimRequired} IsSpotLeadClosure={IsSpotLeadClosure} />
                </Grid>

            </Grid>
        </Box >
        <ModalPopup
            show={showModalSetup}
            onHide={() => setShowModalSetup(!showModalSetup)}
            size={"sm"}
            centered={true}
            modelHeader={"Please enable your location"}
            modelContent={
                <Button
                    onClick={handleClose}
                //   onClick={() => {
                // setShowModalSetup(false)
                //   }}
                >
                    CLOSE
                </Button>
            }
            backdrop={"static"}
        />
    </div >
}
export default LeadLayout;
