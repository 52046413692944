import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  getMobileOS,
} from "../../utils/utils";
import { useSelector} from "react-redux";
import {
  SIMPLIFY_ANDROID_APP_URL,
  SIMPLIFY_LOGIN_URL,
  SIMPLIFY_SSO_TOKEN,
  SIMPLIFY_WEB_APP_URL,
} from "../../api/baseURL";

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  &:hover {
    background: #252831;
    border-left: 4px solid #632ce4;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

//   const Button = styled.a`
//   display: inline-block;
//   border-radius: 3px;
//   padding: 0.5rem 0;
//   margin: 0.5rem 1rem;
//   width: 11rem;
//   background: transparent;
//   color: white;
//   border: 2px solid white;
//   ${props => props.primary && css`
//     background: white;
//     color: palevioletred;
//   `}
// `;

const DropdownLink = styled(Link)`
  background: #414757;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  &:hover {
    background: #632ce4;
    cursor: pointer;
  }
`;

const SubMenu = ({ item, isdisabled }) => {
  const commonState = useSelector((select) => select);
  const userState = commonState.userReducer;
  const roleId=userState.profileInfo.roleId
  const channelId=userState.profileInfo.channelid

  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);
  
  const openInNewTab = (href) => {
    let redirectUrl = "";
    const getDeviceType = getMobileOS();
    const userData = userState.profileInfo;
    
    const matchQuery = `${"Agent_Code="}${userData.agentCode ? userData.agentCode : userData.employeeCode
      }${"&AgentType="}${userData.userType}${"&Source=Actify"}${"&Email="}${userData.email
      }${"&Token="}${SIMPLIFY_SSO_TOKEN}`;
    if (getDeviceType === "Android") {
      redirectUrl = `${SIMPLIFY_ANDROID_APP_URL}${matchQuery}`;
    } else {
      redirectUrl = `${SIMPLIFY_WEB_APP_URL}${matchQuery}`;
    }
    // Object.assign(document.createElement("a"), {
    //   target: "_blank",
    //   rel: "noopener noreferrer",
    //   href: redirectUrl,
    // })
    window.open(redirectUrl, "_blank")
  }
  const handleClick = (menuItem) => {
    if (menuItem.id === 5) {
      openInNewTab(SIMPLIFY_LOGIN_URL);
      return;
    }
    if (menuItem.id === 31) {
      //https://dista-ai-india41.appspot.com/#!/login
      window.open("https://indiafirst.dista.live/", "_blank");
      return;
    }
  };

  return (
    <>
    <SidebarLink style={{ 'pointer-events': isdisabled ? 'none' : 'auto' }}to={item.title == "Simplify" ? "" : item.route} onClick={ item.id === 5 || item.id === 31 ? () => handleClick(item) : item.subNav && showSubnav}>
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
              ? item.iconClosed
              : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav?.map((ele, index) => {
          return (
           roleId=="BDM" && ele.id == 29 &&  channelId==21 || roleId=="BDM" && ele.id == 30 &&  channelId==21 ?
            <></> :
            <DropdownLink style={{ 'pointer-events': isdisabled ? 'none' : 'auto' }} to={ele.route} key={index}>
              {ele.icon}
              <SidebarLabel>{ele.title}</SidebarLabel>
            </DropdownLink>
          )
        })}
    </>
  );
};

export default SubMenu;