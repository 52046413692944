import { createSlice } from '@reduxjs/toolkit'
const leadListSlice = createSlice({
  name: "leadList",
  initialState: {
    listLead: {},
    listLoad: false,
    listerror: false,
    getLeadData: [],
    getCoords: {},
    getLoginCount: {},
    getLeadDraftData: [],
    getBacklogData: [],
    getLeadsOverView: 'all',
    getLeadsOverviewData: {},
    LeadOverViewDashboard: {},
    getPersistancyLeadData: [],
    getPersistancyLeadDraftData: [],
    getPersistancyBacklogData: [],
    getCampaignLeadData: [],
    getCampaignLeadDraftData: [],
    getCampaignBacklogData: [],
    selectedLeadId: [],
    bdmList: [],
    getPersistancyLeadsOverView: 'all',
    getPersistancyLeadsOverviewData: {},
    PersistancyLeadOverViewDashboard: {},
    campaignLeadCampaignNames: [],
    campaingLeadDashboardOverview: {},
    businessLeadSourceDashboardOverview: {},
    searchLeadData: [],
    getMenuDisabled: {},
    getBusinessLeadsOverviewData: {},
    BusinessLeadOverViewDashboard: {},
    getRecruitmentLeadsOverView: 'all',
    getRecruitmentLeadsOverviewData:{},
    RecruitmentLeadOverViewDashboard:{},
  },
  reducers: {
    leadListLoading: state => {
      state.listLoad = true;
    },
    leadListSuccess: (state, action) => {
      state.listLead = action.payload;
      state.listLoad = false;
    },
    leadListError: (state, action) => {
      state.listerror = action.payload;
      state.listLoad = false;
    },
    getLeadList: (state, action) => {
      state.getLeadData = action.payload;
    },
    getCoords: (state, action) => {
      state.getCoords = action.payload
    },
    setLoginCount: (state, action) => {
      state.getLoginCount = action.payload
    },
    getLeadDraftListData: (state, action) => {
      state.getLeadDraftData = action.payload;
    },
    getLeadBacklogListData: (state, action) => {
      state.getBacklogData = action.payload
    },
    setLeadsOverview: (state, action) => {
      state.getLeadsOverView = action.payload
    },
    getLeadsOverviewData: (state, action) => {
      state.getLeadsOverviewData = action.payload
    },
    getLeadOverViewDashboardData: (state, action) => {
      state.LeadOverViewDashboard = action.payload
    },
    getPersistancyLeadList: (state, action) => {
      state.getPersistancyLeadData = action.payload
    },
    getPersistancyLeadDraftListData: (state, action) => {
      state.getPersistancyLeadDraftData = action.payload;
    },
    getPersistancyLeadBacklogListData: (state, action) => {
      state.getPersistancyBacklogData = action.payload
    },
    getCampaignLeadList: (state, action) => {
      state.getCampaignLeadData = action.payload
    },
    getCampaignLeadDraftListData: (state, action) => {
      state.getCampaignLeadDraftData = action.payload;
    },
    getCampaignLeadBacklogListData: (state, action) => {
      state.getCampaignBacklogData = action.payload
    },
    setSelectedLeadId: (state, action) => {
      state.selectedLeadId = action.payload
    },
    setBDMList: (state, action) => {
      state.bdmList = action.payload
    },
    setPersistanyLeadsOverview: (state, action) => {
      state.getPersistancyLeadsOverView = action.payload
    },
    getPersistancyLeadsOverviewData: (state, action) => {
      state.getPersistancyLeadsOverviewData = action.payload
    },
    getPersistancyLeadOverViewDashboardData: (state, action) => {
      state.PersistancyLeadOverViewDashboard = action.payload
    },
    getCampaignLeadCampaignNamesAction: (state, action) => ({
      ...state, campaignLeadCampaignNames: action.payload
    }),
    setCampaingLeadDashboardOverview: (state, action) => (
      {
        ...state, campaingLeadDashboardOverview: action.payload
      }
    ),
    setBusinessLeadSourceDashboardOverview: (state, action) => (
      {
        ...state, businessLeadSourceDashboardOverview: action.payload
      }
    ),
    getSearchLeadList: (state, action) => {
      state.searchLeadData = action.payload
    },
    setMenuDisabled: (state, action) => {
      state.getMenuDisabled = action.payload
    },
    getBusinessLeadsOverviewData: (state, action) => {
      state.getBusinessLeadsOverviewData = action.payload
    },
    getBusinessLeadOverViewDashboardData: (state, action) => {
      state.BusinessLeadOverViewDashboard = action.payload
    },
    setRecruitmentLeadsOverview: (state, action) => {
      state.getRecruitmentLeadsOverView = action.payload
    },
    getRecruitmentLeadsOverviewData: (state, action) => {
      state.getRecruitmentLeadsOverviewData = action.payload
    },
    getRecruitmentLeadOverViewDashboardData: (state, action) => {
      state.RecruitmentLeadOverViewDashboard = action.payload
    },

  }
});

export const { leadListLoading, leadListSuccess, leadListError,
  getLeadList, getLeadDraftListData, getLeadBacklogListData, getCoords, setLoginCount, setMenuDisabled,
  setLeadsOverview, getLeadsOverviewData, getLeadOverViewDashboardData,
  getPersistancyLeadList, getPersistancyLeadDraftListData, getPersistancyLeadBacklogListData,
  setSelectedLeadId, setBDMList, getCampaignLeadList, getCampaignLeadDraftListData,
  getCampaignLeadBacklogListData, setPersistanyLeadsOverview, getPersistancyLeadsOverviewData,
  getPersistancyLeadOverViewDashboardData, getCampaignLeadCampaignNamesAction,
  setCampaingLeadDashboardOverview, getSearchLeadList, setBusinessLeadSourceDashboardOverview,
  getBusinessLeadsOverviewData, getBusinessLeadOverViewDashboardData, 
  setRecruitmentLeadsOverview ,getRecruitmentLeadsOverviewData,getRecruitmentLeadOverViewDashboardData } = leadListSlice.actions
export default leadListSlice.reducer



