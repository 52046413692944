
import React, { useState, useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DynamicForm from "f1-dynamic-form-react";
import { LeadSourceFilterForm, customValidators } from "./leadFilterForm";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { activateErrorMessageBar } from "../../../redux/actions";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  drawerMobile: {
    display: "none",
    [theme.breakpoints.down('xs')]: {
      display: "block"
    },
    [theme.breakpoints.down('sm')]: {
      display: "block"
    }
  },
  desktopFilter: {
    [theme.breakpoints.down('xs')]: {
      display: "none"
    },
    [theme.breakpoints.down('sm')]: {
      display: "none"
    }
  }
}));


const LeadSourceFilter = ({ getFilteredLeads, getAllLeads, parent, handleClose, filterState, setFilterState, setSelectedFilter, anchor ,IsRecruitmentlead}) => {

  const dispatch = useDispatch();
  const [formData, setFormData] = useState(LeadSourceFilterForm)
  const { LeadSource } = useSelector((state) => state.commonReducer)

  const onChangeHandler = (event, key) => {
    setFilterState({ ...filterState, [key]: event.target.value });
  }
  const onSubmitHandler = (model) => {

    let { teamMember } = model;

    if(model.leadSource !== ''){
      handleClose();
      getFilteredLeads(model, teamMember);
    } else {
      dispatch(activateErrorMessageBar('Please select anyone to apply filter'))
    }

  };
  const onResetHandler = () => {
    setFilterState({
      ...filterState,
      ExpectedLeadClosureDate: "null",
      CreatedDateTime: "null",
      MeetingOn: "null",
      LastUpdateDateTime: "null",
      branchNameCode: 0,
      currentStatus: 0,
      currentstatus: [],
      branchNameCodelabel: "",
      typeOfSP: 0,
      productlabel: "",
      leadSource: 0,
      LeadTypeID: 0,
      activityIDlabel: "",
      LeadName: "",
      MeetingDate: "null",
      fromdate: "null",
      todate: "null",
      MobileNumber: "",
      CreatedDateStartTIme: "null",
      CreatedDateEndTIme: "null",
      Modifiedstartdate: "null",
      Modifiedenddate: "null",
      userType: "",
      MobileNo: "",
      campaign: "null",
      IsRecruitmentlead: IsRecruitmentlead ? 1 : 0,
    })



    getAllLeads()
  };

  useEffect(() => {
    const copyFormData = [...formData]
    copyFormData[0].options = LeadSource;
    setFormData(copyFormData)
  }, [ LeadSource])

  const classes = useStyles();
  return (
    <div className={anchor ? classes.drawerMobile : classes.desktopFilter}>
      <div className="filterBox">
        <h4 className="filterSec">
          <FilterAltIcon className="icnFilter" /> Filter By <CloseIcon className={classes.drawerMobile} style={{ float: 'right', cursor: 'pointer' }} onClick={handleClose} />
        </h4>
        <div className="fformBox">
          <DynamicForm
            key={1}
            className="dynamicForm leadFilters  leadFiltersMobile"
            title={" "}
            buttonSubmit={t("Apply Filter")}
            defaultValues={filterState}
            customValidators={customValidators}
            model={formData}
            onSubmit={(model) => onSubmitHandler(model)}
            disableSubmitOnInValid={false}
            design="bootrstap"
            materialOutline={false}
            onChange={(event, key) => onChangeHandler(event, key)}
            extaraActionFormButton={
              <span className="reset" onClick={onResetHandler} ><RestartAltIcon /> Reset</span>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(LeadSourceFilter);
